import React, { useMemo } from 'react';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebaseApp from 'firebase/app';

import { firebaseAuth } from '../firebase';

import { useAuthReplace } from './auth/useAuthReplace';
import { useFromLocationState } from './auth/useFromLocationState';

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- Provider types are private. It's safe to ignore types for now since this code might be replaced by custom login flow in the future.
const signInOptions: any[] = [
  {
    provider: firebaseApp.auth.GoogleAuthProvider.PROVIDER_ID,
  },
];

// Enable email only in development or when forced via secret URL query param
if (
  process.env.NODE_ENV !== 'production' ||
  window.location.search.indexOf('enableEmailAuthProvider') >= 0
) {
  signInOptions.push({
    provider: firebaseApp.auth.EmailAuthProvider.PROVIDER_ID,
    requireDisplayName: false,
  });
}

// https://github.com/firebase/firebaseui-web/#configuration
const defaultUiConfig = {
  siteName: 'Habit Tracker',
  signInFlow: 'popup',
  credentialHelper: 'none',
  signInSuccessUrl: '/',
  signInOptions,
};

export const LoginPage: React.FC = () => {
  useAuthReplace();
  const fromLocationState = useFromLocationState();
  const uiConfig = useMemo(
    () => ({ ...defaultUiConfig, signInSuccessUrl: fromLocationState.pathname }),
    [fromLocationState]
  );
  return (
    <div>
      <div style={{ textAlign: 'center' }}>
        <h1>Habit Tracker</h1>
        <span style={{ fontSize: '1.25rem', color: 'red' }}>
          This app is still in early development. <br />
          Don't use it. Or use it at your own risk. <br />
          (Anything can happen)
        </span>
      </div>
      <StyledFirebaseAuth firebaseAuth={firebaseAuth} uiConfig={uiConfig} />
    </div>
  );
};
