import { TimeRange } from './domain/weakEntities/timeRange';
import { firebaseFirestore, firebaseAuth, Timestamp } from './firebase';
import { dateToTimestamp } from './utils/datetime';

// UserData

function userDataDocument() {
  const userId = firebaseAuth.currentUser?.uid;
  return firebaseFirestore.doc(`userData/${userId}`);
}

// Habits

function habitsCollection() {
  return userDataDocument().collection('habits');
}

function habitById(id: ID) {
  return habitsCollection().doc(id);
}

// Habit Instances

function habitInstancesCollection() {
  return userDataDocument().collection('habitInstances');
}

function habitInstancesByTimeRange(timeRange: TimeRange) {
  let from: import('firebase/app').firestore.Timestamp | undefined;
  let to: import('firebase/app').firestore.Timestamp | undefined;
  switch (timeRange.type) {
    case 'all':
      from = Timestamp.fromMillis(0);
      to = dateToTimestamp(new Date());
      break;
    case 'custom':
      from = timeRange.from;
      to = timeRange.to;
      break;
    case 'lastYear':
      {
        const lastYear = new Date();
        lastYear.setFullYear(lastYear.getFullYear() - 1);
        from = dateToTimestamp(lastYear);
        to = dateToTimestamp(new Date());
      }
      break;
    case 'lastMonth':
      {
        const lastMonth = new Date();
        lastMonth.setMonth(lastMonth.getMonth() - 1);
        from = dateToTimestamp(lastMonth);
        to = dateToTimestamp(new Date());
      }
      break;
    case 'lastWeek':
      {
        const lastWeek = new Date();
        lastWeek.setDate(lastWeek.getDate() - 7);
        from = dateToTimestamp(lastWeek);
        to = dateToTimestamp(new Date());
      }
      break;
    case 'today':
      {
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        from = dateToTimestamp(yesterday);
        to = dateToTimestamp(new Date());
      }
      break;
    default:
      throw new Error(`Invalid timeRange=${timeRange.type}`);
  }
  if (!from || !to) throw new Error(`Invalid query range from=${from}, to=${to}`);
  return habitInstancesCollection().where('startDate', '>=', from).where('startDate', '<=', to);
}

// Charts

function chartsCollection() {
  return userDataDocument().collection('charts');
}

function chartById(id: ID) {
  return chartsCollection().doc(id);
}

// Helpers

function toEntity<TEntity>(
  queryDocumentSnapshot:
    | import('firebase/app').firestore.QueryDocumentSnapshot
    | import('firebase/app').firestore.DocumentSnapshot
) {
  // TODO: While QueryDocumentSnapshot guarantees that the document exists, DocumentSnapshot does not.
  // Maybe they should be separated.
  return ({ id: queryDocumentSnapshot.id, ...queryDocumentSnapshot.data() } as unknown) as TEntity;
}

function toEntityCollection<TEntity>(
  querySnapshot: import('firebase/app').firestore.QuerySnapshot
) {
  return querySnapshot.docs.map((queryDocumentSnapshot) =>
    toEntity<TEntity>(queryDocumentSnapshot)
  );
}

export {
  toEntity,
  toEntityCollection,
  habitsCollection,
  habitById,
  habitInstancesCollection,
  habitInstancesByTimeRange,
  chartsCollection,
  chartById,
};
