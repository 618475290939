import React from 'react';
import { IconButton, IconButtonProps, Tooltip } from '@material-ui/core';
import { AddCircle } from '@material-ui/icons';

export const AddButton: React.FC<IconButtonProps> = (props) => {
  return (
    <Tooltip title="Add instance">
      <IconButton {...props} aria-label="add">
        <AddCircle fontSize="inherit" />
      </IconButton>
    </Tooltip>
  );
};
