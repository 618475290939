import React, { useMemo } from 'react';
import { Bar, BarChart, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { appStackedChartLegendFormatterFactory } from '../appStackedChartLegendFormatterFactory';
import { appStackedChartTooltipFormatterFactory } from '../appStackedChartTooltipFormatterFactory';
import { AppStackedChartYAxisTick } from '../AppStackedChartYAxisTick';

export interface StackedBarData {
  barMetadata: {
    property: string;
    label: string;
    color: string;
  }[];
  dataPoints: {
    groupLabel: string;
    [P: string]: string | number;
  }[];
}

interface Props {
  data: StackedBarData;
}

export const AppStackedBarChart: React.FC<Props> = ({ data: { dataPoints, barMetadata } }) => {
  const legendFormatter = useMemo(() => appStackedChartLegendFormatterFactory(barMetadata), [
    barMetadata,
  ]);
  const tooltipFormatter = useMemo(() => appStackedChartTooltipFormatterFactory(barMetadata), [
    barMetadata,
  ]);
  return (
    <ResponsiveContainer aspect={1}>
      <BarChart data={dataPoints}>
        <XAxis dataKey="groupLabel" />
        <YAxis tick={AppStackedChartYAxisTick} />
        <Tooltip formatter={tooltipFormatter} labelStyle={{ color: 'black' }} />
        <Legend formatter={legendFormatter} />
        {barMetadata.map(({ property, color }) => (
          <Bar dataKey={property} fill={color} key={property} stackId="a" />
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
};
