import React, { useEffect, useState } from 'react';

import { habitInstancesCollection, toEntity } from '../../queries';
import type { InstanceEntity } from '../../domain/instance';
import { dateToDatetimeLocal, datetimeLocalToTimestamp } from '../../utils/datetime';
import { secondsToDurationString } from '../../utils/duration';
import { deleteInstanceAndUpdateHabit } from '../../mutations/deleteInstanceAndUpdateHabit';
import { logAnalyticsException } from '../../analytics';

export const ListInstances: React.FC = () => {
  const [habitInstances, setHabitInstances] = useState<InstanceEntity[]>([]);
  const [fromDate, setFromDate] = useState(() => dateToDatetimeLocal(getInitialFromDate()));
  const [toDate, setToDate] = useState(() => dateToDatetimeLocal(new Date()));

  useEffect(() => {
    return habitInstancesCollection()
      .where('startDate', '>=', datetimeLocalToTimestamp(fromDate))
      .where('startDate', '<=', datetimeLocalToTimestamp(toDate))
      .orderBy('startDate', 'desc')
      .onSnapshot((querySnapshot) =>
        setHabitInstances(
          querySnapshot.docs.map((queryDocumentSnapshot) =>
            toEntity<InstanceEntity>(queryDocumentSnapshot)
          )
        )
      );
  }, [fromDate, toDate]);

  async function handleDelete({ id: instanceId, habitId }: InstanceEntity) {
    if (!window.confirm(`Delete instance?`)) return;
    try {
      await deleteInstanceAndUpdateHabit(instanceId);
    } catch (error) {
      logAnalyticsException(
        error,
        `The call to deleteInstanceAndUpdateHabit failed. The habit's statistics could have invalid values.`,
        {
          habitId,
          instanceId,
        }
      );
      window.alert(error.message);
    }
  }

  function fromDateOnChange(event: React.ChangeEvent<HTMLInputElement>) {
    setFromDate(event.target.value);
  }

  function toDateOnChange(event: React.ChangeEvent<HTMLInputElement>) {
    setToDate(event.target.value);
  }

  function lastMonthOnClick() {
    const date = new Date();
    setToDate(dateToDatetimeLocal(date));
    date.setMonth(date.getMonth() - 1);
    setFromDate(dateToDatetimeLocal(date));
  }

  function todayOnClick() {
    const date = new Date();
    setToDate(dateToDatetimeLocal(date));
    date.setHours(0);
    date.setMinutes(0);
    setFromDate(dateToDatetimeLocal(date));
  }

  return (
    <div>
      <h2>Instances</h2>
      <div>
        <label htmlFor="date">From</label>
        <input
          id="date"
          name="date"
          type="datetime-local"
          value={fromDate}
          onChange={fromDateOnChange}
        />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <label htmlFor="date">To</label>
        <input
          id="date"
          name="date"
          type="datetime-local"
          value={toDate}
          onChange={toDateOnChange}
        />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <button onClick={lastMonthOnClick}>last month</button>
        <button onClick={todayOnClick}>today</button>
      </div>
      <table>
        <thead>
          <tr>
            <td>Name</td>
            <td>Start Date</td>
            <td>End Date</td>
            <td>Duration</td>
            <td>Sets</td>
            <td>Set Count</td>
            <td>Set Repetitions Count</td>
            <td>Actions</td>
          </tr>
        </thead>
        <tbody>
          {habitInstances.map((instance) => (
            <tr key={instance.id}>
              <td>{instance.habitName}</td>
              <td>{instance.startDate.toDate().toLocaleString()}</td>
              <td>{instance.endDate?.toDate().toLocaleString()}</td>
              <td>
                {instance.durationSeconds && secondsToDurationString(instance.durationSeconds)}
              </td>
              <td>{instance.sets}</td>
              <td>{instance.setCount}</td>
              <td>{instance.setRepetitionsCount}</td>
              <td>
                <button onClick={() => handleDelete(instance)}>Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

/** A good default is start of the current day */
function getInitialFromDate() {
  const date = new Date();
  date.setHours(0);
  date.setMinutes(0);
  return date;
}
