import React from 'react';

import { ListHabits } from './ListHabits';
import { AddHabit } from './AddHabit';

export const HabitsPageLegacy: React.FC = () => {
  return (
    <div>
      <h1>Habits Page</h1>
      <AddHabit />
      <ListHabits />
    </div>
  );
};
