import { firebaseFirestore, Timestamp } from '../firebase';
import { habitInstancesCollection, habitsCollection } from '../queries';
import { HabitStatistics } from '../domain/habit';

export function deleteInstanceAndUpdateHabit(deletedInstanceId: ID) {
  return firebaseFirestore.runTransaction(async (transaction) => {
    const instance = await transaction.get(habitInstancesCollection().doc(deletedInstanceId));
    const habitId = instance.get('habitId');
    if (!habitId) return;
    const habit = await transaction.get(habitsCollection().doc(habitId));
    if (!habit.exists) return;

    const habitStatistics = await computeHabitStatistics(habitId, deletedInstanceId);
    transaction.update(habit.ref, habitStatistics);
    transaction.delete(instance.ref);
  });
}

async function computeHabitStatistics(
  habitId: ID,
  deletedInstanceId: ID
): Promise<HabitStatistics> {
  const instances = await habitInstancesCollection().where('habitId', '==', habitId).get();

  const result = {
    lastInstanceDate: Timestamp.fromMillis(0),
    instanceCount: 0,
    totalInstanceTimeSeconds: 0,
    totalSetCount: 0,
    maxSetCount: 0,
    totalRepetitionsCount: 0,
    maxRepetitionsInAnInstance: 0,
    maxRepetitionsInASet: 0,
  };

  instances.forEach((instance) => {
    if (instance.id === deletedInstanceId) return;
    const instanceData = instance.data();

    if (result.lastInstanceDate < instanceData.startDate) {
      result.lastInstanceDate = instanceData.startDate;
    }

    result.instanceCount += 1;
    result.totalInstanceTimeSeconds += instanceData.durationSeconds ?? 0;
    result.totalSetCount += instanceData.setCount ?? 0;
    result.maxSetCount = Math.max(result.maxSetCount, instanceData.setCount ?? 0);
    result.totalRepetitionsCount += instanceData.setRepetitionsCount ?? 0;
    result.maxRepetitionsInAnInstance = Math.max(
      result.maxRepetitionsInAnInstance,
      instanceData.setRepetitionsCount ?? 0
    );
    result.maxRepetitionsInASet = ((instanceData.sets as Maybe<string>) ?? '')
      .split(',')
      .map((set) => Number(set))
      .reduce(
        (accumulator, currentValue) => Math.max(accumulator, currentValue),
        result.maxRepetitionsInASet
      );
  });

  return result;
}
