import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { Container } from '@material-ui/core';

import { HabitsPageLegacy } from '../HabitsPageLegacy/HabitsPageLegacy';
import { InstancesPageLegacy } from '../InstancesPageLegacy/InstancesPageLegacy';
import { HabitsPage } from '../HabitsPage/HabitsPage';
import { StatisticsPage } from '../Statistics/StatisticsPage';
import { AddInstance } from '../InstancesPageLegacy/AddInstance';

import { TopBar } from './TopBar/TopBar';
import { HideOnScroll } from './HideOnScroll';

export const MainLayout: React.FC = () => {
  return (
    <>
      <HideOnScroll>
        <TopBar />
      </HideOnScroll>
      <Container component="main">
        <Switch>
          <Route exact path="/">
            <AddInstance />
          </Route>
          <Route path="/habits">
            <HabitsPage />
          </Route>
          <Route path="/habits-legacy">
            <HabitsPageLegacy />
          </Route>
          <Route path="/instances-legacy">
            <InstancesPageLegacy />
          </Route>
          <Route path="/stats">
            <StatisticsPage />
          </Route>
        </Switch>
      </Container>
    </>
  );
};
