import randomColor from 'randomcolor';

import { ChartEntity } from '../../../../domain/chart';
import { HabitEntity } from '../../../../domain/habit';
import { InstanceEntity } from '../../../../domain/instance';
import { TimeRange } from '../../../../domain/weakEntities/timeRange';
import { updateChartCachedData } from '../../../../mutations/updateChartCachedData';
import { habitInstancesByTimeRange, toEntityCollection } from '../../../../queries';
import { secondsToDurationLabel } from '../../../../utils/duration';
import { filterHabits } from '../../../../utils/filterHabits';
import { monthKeys } from '../../../../utils/monthKeys';
import { PieData } from '../SharedCharts/AppPieChart/AppPieChart';
import { StackedBarData } from '../SharedCharts/AppStackedBarChart/AppStackedBarChart';

import { IndependentChartData } from './IndependentChartData';

export async function computeIndependentChartData(
  chartEntity: ChartEntity<IndependentChartData>,
  habits: HabitEntity[],
  shouldUpdate: boolean
) {
  const cachedData = await computeCachedData(chartEntity, habits);

  if (shouldUpdate) {
    await updateChartCachedData(chartEntity.id, cachedData);
  }

  return cachedData;
}

function computeCachedData(
  chartEntity: ChartEntity<IndependentChartData>,
  habits: HabitEntity[]
): Promise<PieData | StackedBarData> {
  switch (chartEntity.variant) {
    case 'Pie':
      return Promise.resolve(computePie(chartEntity, habits));
    case 'StackedBars':
    case 'StackedAreas':
      return computeStackedBars(chartEntity.data.timeRange, chartEntity.data.selectedHabits);
    default:
      throw new Error(
        `Variant=${chartEntity.variant} is not covered by "computeCachedData" function`
      );
  }
}

async function computePie(
  chartEntity: ChartEntity<IndependentChartData>,
  habits: HabitEntity[]
): Promise<PieData> {
  if (chartEntity.data.timeRange.type === 'all') {
    return computePieFromHabits(chartEntity.data.selectedHabits, habits);
  }
  return computePieFromInstances(chartEntity.data.timeRange, chartEntity.data.selectedHabits);
}

function computePieFromHabits(selectedHabits: ID[], habits: HabitEntity[]): PieData {
  const filteredHabits = filterHabits(habits, selectedHabits);
  const slices: PieData = [];
  let totalTime = 0;

  filteredHabits.forEach((habit) => {
    if (habit.connotation === 'NEUTRAL') return;
    const value = habit.totalInstanceTimeSeconds ?? 0;
    totalTime += value;
    slices.push({
      label: habit.name,
      subLabel: 'fill-later',
      value,
      color: 'fill-later',
    });
  });

  const colors = getRandomColors(slices.length);

  slices.forEach((slice, index) => {
    const percent = totalTime > 0 ? Math.round((slice.value / totalTime) * 100) : 0;
    slice.subLabel = `${secondsToDurationLabel(slice.value)} (${percent}%)`;
    slice.color = colors[index];
  });

  return slices;
}

async function computePieFromInstances(
  timeRange: TimeRange,
  selectedHabits: ID[]
): Promise<PieData> {
  const instances = toEntityCollection<InstanceEntity>(
    await habitInstancesByTimeRange(timeRange).get()
  );

  const selectedHabitsSet = new Set(selectedHabits);
  const timeByHabit = new Map<string, number>();
  const habitNames = new Map<string, string>();
  let totalTime = 0;

  instances.forEach((instance) => {
    if (instance.habitConnotation === 'NEUTRAL') return;
    if (!instance.durationSeconds) return;
    if (!selectedHabitsSet.has(instance.habitId)) return;
    const value = instance.durationSeconds;
    totalTime += value;
    const currentTime = timeByHabit.get(instance.habitId) ?? 0;
    timeByHabit.set(instance.habitId, currentTime + value);
    habitNames.set(instance.habitId, instance.habitName);
  });

  const colors = getRandomColors(timeByHabit.size);
  const slices: PieData = [];
  selectedHabits.forEach((habitId) => {
    const value = timeByHabit.get(habitId) ?? 0;
    if (value <= 0) return;
    const percent = totalTime > 0 ? Math.round((value / totalTime) * 100) : 0;
    slices.push({
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- name will always exist
      label: habitNames.get(habitId)!,
      subLabel: `${secondsToDurationLabel(value)} (${percent}%)`,
      value,
      color: colors[slices.length],
    });
  });

  return slices;
}

async function computeStackedBars(
  timeRange: TimeRange,
  selectedHabits: ID[]
): Promise<StackedBarData> {
  const instances = toEntityCollection<InstanceEntity>(
    await habitInstancesByTimeRange(timeRange).get()
  );

  const selectedHabitsSet = new Set(selectedHabits);
  const habitNames = new Map<string, string>();
  const dataByDay = new Map<
    string,
    { sortKey: number; groupLabel: string; [property: string]: string | number }
  >();

  instances.forEach((instance) => {
    if (instance.habitConnotation === 'NEUTRAL') return;
    if (!instance.durationSeconds) return;
    if (!selectedHabitsSet.has(instance.habitId)) return;
    const startDate = instance.startDate.toDate();
    const day = startDate.getDate();
    const month = startDate.getMonth();
    const key = `${monthKeys[month]}, ${day}`;
    const keyExists = dataByDay.has(key);
    const data = keyExists
      ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- value is guaranteed to exist
        dataByDay.get(key)!
      : { sortKey: instance.startDate.seconds, groupLabel: key, [instance.habitId]: 0 };
    const currentValue = (data[instance.habitId] as number) ?? 0;
    (data[instance.habitId] as number) = currentValue + instance.durationSeconds;
    if (!keyExists) dataByDay.set(key, data);
    habitNames.set(instance.habitId, instance.habitName);
  });

  const rawData = Array.from(dataByDay.values());
  rawData.sort((x, y) => x.sortKey - y.sortKey);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars -- removes sortKey from the final object
  const dataPoints = rawData.map(({ sortKey, ...dataPoint }) => dataPoint);

  const colors = getRandomColors(habitNames.size);
  const barMetadata = Array.from(habitNames.keys()).map((habitId, index) => ({
    property: habitId,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- name will always exist
    label: habitNames.get(habitId)!,
    color: colors[index],
  }));

  return { barMetadata, dataPoints };
}

function getRandomColors(count: number) {
  return randomColor({ luminosity: 'dark', count, seed: 12 });
}
