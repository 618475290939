import React from 'react';

import type { Connotation, HabitInput } from '../../domain/habit';
import { habitsCollection } from '../../queries';
import { Timestamp } from '../../firebase';

export const AddHabit: React.FC = () => {
  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const habitInput: HabitInput = {
      name: formData.get('name') as string,
      connotation: formData.get('connotation') as Connotation,
      lastInstanceDate: Timestamp.fromMillis(0),
      instanceCount: 0,
      totalInstanceTimeSeconds: 0,
      totalSetCount: 0,
      maxSetCount: 0,
      totalRepetitionsCount: 0,
      maxRepetitionsInAnInstance: 0,
      maxRepetitionsInASet: 0,
    };
    await habitsCollection().add(habitInput);
  }

  return (
    <form onSubmit={handleSubmit}>
      <fieldset>
        <legend>Add Habit</legend>
        <label htmlFor="name">Name</label>
        <input id="name" name="name" type="text" />
        <br />
        <label htmlFor="connotation">Connotation</label>
        <select id="connotation" name="connotation">
          <option value="POSITIVE">Positive</option>
          <option value="NEUTRAL">Neutral</option>
          <option value="NEGATIVE">Negative</option>
        </select>
        <br />
        <input type="submit" value="Save" />
      </fieldset>
    </form>
  );
};
