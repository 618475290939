import React, { useState, useEffect } from 'react';
import { Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';

import { HabitEntity } from '../../domain/habit';
import { habitsCollection, toEntity } from '../../queries';

export const ListHabits: React.FC = () => {
  const [habits, setHabits] = useState<HabitEntity[]>([]);

  useEffect(() => {
    return habitsCollection()
      .orderBy('lastInstanceDate', 'desc')
      .onSnapshot((querySnapshot) =>
        setHabits(
          querySnapshot.docs.map((queryDocumentSnapshot) =>
            toEntity<HabitEntity>(queryDocumentSnapshot)
          )
        )
      );
  }, []);

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Dessert (100g serving)</TableCell>
          <TableCell align="right">Calories</TableCell>
          <TableCell align="right">Fat&nbsp;(g)</TableCell>
          <TableCell align="right">Carbs&nbsp;(g)</TableCell>
          <TableCell align="right">Protein&nbsp;(g)</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {habits.map((habit) => (
          <TableRow key={habit.name}>
            <TableCell component="th" scope="row">
              {habit.name}
              <br></br>
              <br></br>
              <br></br>
              <br></br>
            </TableCell>
            <TableCell align="right">{habit.connotation}</TableCell>
            <TableCell align="right">{habit.instanceCount}</TableCell>
            <TableCell align="right">{habit.totalSetCount}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
