import { Form, Formik, FormikProps } from 'formik';
import React, { useRef } from 'react';
import { object as yupObject, string as yupString } from 'yup';

import { FormikValueChangeEffect } from '../../../FormikValueChangeEffect';
import { TextField } from '../../../TextField';

export interface ChartFormValues {
  title: string;
  notes?: string;
}

const formValidationSchema = yupObject({
  title: yupString().required('Required'),
  notes: yupString(),
});

interface Props {
  initialValues: ChartFormValues;
  onChange: (values: ChartFormValues) => void;
}

export const ChartForm: React.FC<Props> = ({ initialValues, onChange }) => {
  const formikRef = useRef<FormikProps<ChartFormValues> | null>(null);

  return (
    <Formik<ChartFormValues>
      initialValues={initialValues}
      innerRef={(instance) => (formikRef.current = instance)}
      validateOnMount
      validationSchema={formValidationSchema}
      // eslint-disable-next-line @typescript-eslint/no-empty-function -- Formik only handles the form state. Submission happens outside this component.
      onSubmit={() => {}}
    >
      {() => (
        <Form>
          <FormikValueChangeEffect onChange={onChange} />
          <TextField
            fullWidth
            id="title"
            label="Chart Title"
            margin="normal"
            name="title"
            required
          />
          <TextField
            fullWidth
            id="notes"
            label="Chart Notes"
            margin="normal"
            multiline
            name="notes"
          />
        </Form>
      )}
    </Formik>
  );
};
