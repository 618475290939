import React, { useState, useEffect, useRef, useCallback } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { TableContainer, Table, TableBody, TableRow, TableCell, Checkbox } from '@material-ui/core';

import { HabitEntity } from '../../../../../domain/habit';
import { habitsCollection, toEntityCollection } from '../../../../../queries';
import { useIsMounted } from '../../../../../utils/useIsMounted';
import { LoadingButton } from '../../../../LoadingButton';
import { HabitFilter } from '../../../../../domain/weakEntities/habitFilter';

import { HabitsInfiniteListHeader } from './HabitsInfiniteListHeader';
import { ConnotationFilter } from './FilterMenu';

const defaultBatchSize = 5;

const labelClassMap = {
  POSITIVE: 'positiveHabitLabel' as const,
  NEGATIVE: 'negativeHabitLabel' as const,
  NEUTRAL: 'neutralHabitLabel' as const,
};
const labelContentMap = {
  POSITIVE: '+',
  NEGATIVE: '-',
  NEUTRAL: '',
};

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      positiveHabitLabel: {
        color: theme.palette.habitTextPositive.main,
      },
      negativeHabitLabel: {
        color: theme.palette.habitTextNegative.main,
      },
      neutralHabitLabel: {},
    }),
  { name: 'HabitsInfiniteList' }
);

interface Props {
  className?: string;
  initialSelection?: ID[];
  onSelectionChange?: (filter: HabitFilter) => void;
}

export const HabitsInfiniteList: React.FC<Props> = ({
  className,
  initialSelection = [],
  onSelectionChange,
}) => {
  const classes = useStyles();
  const isMounted = useIsMounted();
  const [data, setData] = useState<HabitEntity[]>([]);
  const [loadingState, setLoadingState] = useState({ isLoading: true, canLoadMore: true });
  const [filter, setFilter] = useState<{
    order: Order;
    orderBy: keyof HabitEntity;
  }>({
    order: 'desc',
    orderBy: 'lastInstanceDate',
  });
  const [selectedIds, setSelectedIds] = useState<ID[]>(initialSelection);

  const batchSize = useRef(defaultBatchSize);
  const lastDocument = useRef<import('firebase/app').firestore.QueryDocumentSnapshot | null>(null);

  const dataLength = useRef(data.length);
  dataLength.current = data.length;

  const executeQuery = useCallback(
    (cancelPromiseObject?: { cancel: boolean }) => {
      setLoadingState({ isLoading: true, canLoadMore: true });

      let query = habitsCollection().orderBy(filter.orderBy, filter.order);

      if (lastDocument.current) {
        query = query.startAfter(lastDocument.current);
      }

      query
        .limit(batchSize.current)
        .get()
        .then((querySnapshot) => {
          if (cancelPromiseObject?.cancel || !isMounted.current) return;

          const shouldConcat = !!lastDocument.current;

          const count = querySnapshot.docs.length;
          if (count > 0) {
            lastDocument.current = querySnapshot.docs[count - 1];
          }
          if (count < batchSize.current) {
            setLoadingState({ isLoading: false, canLoadMore: false });
          } else {
            setLoadingState({ isLoading: false, canLoadMore: true });
          }
          batchSize.current = defaultBatchSize;
          const rows = toEntityCollection<HabitEntity>(querySnapshot);
          if (shouldConcat) {
            setData((v) => v.concat(rows));
          } else {
            setData(rows);
          }
        })
        .catch((error) => {
          window.alert(error.message);
        });
    },
    [filter.order, filter.orderBy, isMounted]
  );

  useEffect(() => {
    onSelectionChange && onSelectionChange(selectedIds);
  }, [onSelectionChange, selectedIds]);

  useEffect(() => {
    lastDocument.current = null;
    if (dataLength.current > 0) {
      batchSize.current = dataLength.current;
    }
  }, [filter.order, filter.orderBy]);

  useEffect(() => {
    const cancelPromiseObject = { cancel: false };

    executeQuery(cancelPromiseObject);

    return () => {
      cancelPromiseObject.cancel = true;
    };
  }, [executeQuery]);

  function handleLoadMoreClick() {
    executeQuery();
  }

  function handleRequestSort(orderBy: keyof HabitEntity, order?: Order) {
    const isAsc = filter.orderBy === orderBy && filter.order === 'asc';
    setFilter({
      order: order ?? (isAsc ? 'desc' : 'asc'),
      orderBy,
    });
  }

  function handleSelectAllClick(selectAll: boolean) {
    if (selectAll) {
      const newSelectedIds = data.map(({ id }) => id);
      setSelectedIds(newSelectedIds);
      return;
    }
    setSelectedIds([]);
  }

  function handleConnotationFilterChanged(connotationFilter: ConnotationFilter) {
    if (!connotationFilter) {
      setSelectedIds([]);
      return;
    }
    const newSelectedIds = data
      .filter(({ connotation }) => connotation === connotationFilter)
      .map(({ id }) => id);
    setSelectedIds(newSelectedIds);
  }

  function handleRowClick(event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) {
    const { id } = event.currentTarget;
    const selectedIndex = selectedIds.indexOf(id);
    let newSelected: ID[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedIds, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedIds.slice(1));
    } else if (selectedIndex === selectedIds.length - 1) {
      newSelected = newSelected.concat(selectedIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedIds.slice(0, selectedIndex),
        selectedIds.slice(selectedIndex + 1)
      );
    }

    setSelectedIds(newSelected);
  }

  function isSelected(name: string) {
    return selectedIds.indexOf(name) !== -1;
  }

  return (
    <TableContainer className={className}>
      <Table>
        <HabitsInfiniteListHeader
          order={filter.order}
          orderBy={filter.orderBy}
          rowCount={data.length}
          selectedRows={selectedIds.length}
          onConnotationFilterChanged={handleConnotationFilterChanged}
          onRequestSort={handleRequestSort}
          onSelectAll={handleSelectAllClick}
        />
        <TableBody>
          {data.map((row) => {
            const isItemSelected = isSelected(row.id);
            const labelId = `enhanced-table-checkbox-${row.id}`;

            return (
              <TableRow
                aria-checked={isItemSelected}
                hover
                id={row.id}
                key={row.id}
                role="checkbox"
                selected={isItemSelected}
                tabIndex={-1}
                onClick={handleRowClick}
              >
                <TableCell padding="checkbox">
                  <Checkbox checked={isItemSelected} inputProps={{ 'aria-labelledby': labelId }} />
                </TableCell>
                <TableCell
                  align="left"
                  colSpan={2}
                  component="th"
                  id={labelId}
                  padding="none"
                  scope="row"
                >
                  {row.name}
                  <span className={classes[labelClassMap[row.connotation]]}>
                    {' ' + labelContentMap[row.connotation]}
                  </span>
                </TableCell>
              </TableRow>
            );
          })}
          {loadingState.canLoadMore ? (
            <TableRow>
              <TableCell colSpan={3} padding="none">
                <LoadingButton
                  fullWidth
                  isLoading={loadingState.isLoading}
                  onClick={handleLoadMoreClick}
                >
                  load more
                </LoadingButton>
              </TableCell>
            </TableRow>
          ) : null}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
