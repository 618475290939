import app from 'firebase/app';

const config = {
  apiKey: 'AIzaSyDiMqM49HFTdUNeeDcrvS56smVrj6T0ocs',
  authDomain: 'habit-tracker-develop.firebaseapp.com',
  databaseURL: 'https://habit-tracker-develop.firebaseio.com',
  projectId: 'habit-tracker-develop',
  storageBucket: 'habit-tracker-develop.appspot.com',
  messagingSenderId: '399682354784',
  appId: '1:399682354784:web:f90099ee439cb5a6cc0d05',
  measurementId: 'G-PFE7W427QV',
};

export const firebaseApp = app.initializeApp(config);
