import { Checkbox, TableCell, TableHead, TableRow, TableSortLabel } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import React from 'react';

import { HabitEntity } from '../../../../../domain/habit';

import { FilterMenu, ConnotationFilter } from './FilterMenu';

const useStyles = makeStyles(
  () =>
    createStyles({
      mainCell: {
        display: 'flex',
        justifyContent: 'space-between',
      },
      visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
      },
    }),
  { name: 'HabitsInfiniteListHeader' }
);

interface Props {
  rowCount: number;
  selectedRows: number;
  order: Order;
  /** The name of the data property that will be used to sort */
  orderBy: keyof HabitEntity;
  onSelectAll: (selectAll: boolean) => void;
  onRequestSort: (orderBy: keyof HabitEntity, order?: Order) => void;
  onConnotationFilterChanged: (connotationFilter: ConnotationFilter) => void;
}

export const HabitsInfiniteListHeader: React.FC<Props> = ({
  rowCount,
  selectedRows,
  order,
  orderBy,
  onSelectAll,
  onRequestSort,
  onConnotationFilterChanged,
}) => {
  const classes = useStyles();

  const createSortHandler = (orderBy: keyof HabitEntity) => () => {
    onRequestSort(orderBy);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            checked={rowCount > 0 && selectedRows === rowCount}
            indeterminate={selectedRows > 0 && selectedRows < rowCount}
            inputProps={{ 'aria-label': 'select all' }}
            onChange={(e) => onSelectAll(e.target.checked)}
          />
        </TableCell>
        <TableCell
          className={classes.mainCell}
          padding="none"
          sortDirection={orderBy === 'name' ? order : false}
        >
          <TableSortLabel
            active={orderBy === 'name'}
            direction={orderBy === 'name' ? order : 'asc'}
            onClick={createSortHandler('name')}
          >
            Habits
            {orderBy === 'name' ? (
              <span className={classes.visuallyHidden}>
                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
              </span>
            ) : null}
          </TableSortLabel>
          <FilterMenu
            order={order}
            orderBy={orderBy}
            onConnotationFilterChanged={onConnotationFilterChanged}
            onRequestSort={onRequestSort}
          />
        </TableCell>
      </TableRow>
    </TableHead>
  );
};
