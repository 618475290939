import React from 'react';

import { AddInstance } from './AddInstance';
import { ListInstances } from './ListInstances';

export const InstancesPageLegacy: React.FC = () => {
  return (
    <div>
      <h1>Instances Page</h1>
      <AddInstance />
      <ListInstances />
    </div>
  );
};
