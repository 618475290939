import React, { useEffect, useState } from 'react';

import type { HabitEntity, Connotation } from '../../domain/habit';
import { habitsCollection, toEntity, habitById } from '../../queries';
import { logAnalyticsException } from '../../analytics';
import { deleteHabitAndInstances } from '../../mutations/deleteHabitAndInstances';

import { EditName } from './EditName';

const cellStyle = { border: '1px solid black' };
const centeredCell = { ...cellStyle, textAlign: 'center' as const };

export const ListHabits: React.FC = () => {
  const [habits, setHabits] = useState<HabitEntity[]>([]);
  const [habitBeingEdited, setHabitBeingEdited] = useState<HabitEntity | undefined>();

  useEffect(() => {
    return habitsCollection()
      .orderBy('lastInstanceDate', 'desc')
      .onSnapshot((querySnapshot) =>
        setHabits(
          querySnapshot.docs.map((queryDocumentSnapshot) =>
            toEntity<HabitEntity>(queryDocumentSnapshot)
          )
        )
      );
  }, []);

  function handleEdit(habit: HabitEntity) {
    setHabitBeingEdited({ ...habit });
  }

  function handleCancelEdit() {
    setHabitBeingEdited(undefined);
  }

  async function handleSave() {
    if (habitBeingEdited) {
      await habitById(habitBeingEdited.id).update({ name: habitBeingEdited.name });
      setHabitBeingEdited(undefined);
    }
  }

  async function handleDelete(habitId: ID) {
    if (!window.confirm(`Delete habit?`)) return;
    try {
      await deleteHabitAndInstances(habitId);
    } catch (error) {
      logAnalyticsException(error, 'Habit delete failed', { habitId });
    }
  }

  return (
    <div>
      <h2>Habits</h2>
      <table>
        <thead>
          <tr>
            <th>Con</th>
            <th>Name</th>
            <th>Last Used</th>
            <th>Actions</th>
            <th>instance Count</th>
            <th>total Instance Time Seconds</th>
            <th>total Set Count</th>
            <th>max Set Count</th>
            <th>total Repetitions Count</th>
            <th>max Repetitions In An Instance</th>
            <th>max Repetitions In A Set</th>
          </tr>
        </thead>
        <tbody>
          {habits.map((habit) => (
            <tr key={habit.id}>
              <td style={centeredCell}>{getConnotationSymbol(habit.connotation)}</td>
              <td style={cellStyle}>
                {habitBeingEdited?.id === habit.id ? (
                  <EditName
                    name={habitBeingEdited.name}
                    onChange={(name) =>
                      setHabitBeingEdited((prevValue) => ({ ...prevValue, name } as HabitEntity))
                    }
                  />
                ) : (
                  habit.name
                )}
              </td>
              <td style={cellStyle}>{getLastUsedStr(habit.lastInstanceDate)}</td>
              <td style={cellStyle}>
                {!habitBeingEdited && <button onClick={() => handleEdit(habit)}>Edit</button>}
                {habitBeingEdited?.id === habit.id && <button onClick={handleSave}>Save</button>}
                {habitBeingEdited?.id === habit.id && (
                  <button onClick={handleCancelEdit}>Cancel</button>
                )}
                <button onClick={() => handleDelete(habit.id)}>Delete</button>
              </td>
              <td style={centeredCell}>{habit.instanceCount}</td>
              <td style={centeredCell}>{habit.totalInstanceTimeSeconds}</td>
              <td style={centeredCell}>{habit.totalSetCount}</td>
              <td style={centeredCell}>{habit.maxSetCount}</td>
              <td style={centeredCell}>{habit.totalRepetitionsCount}</td>
              <td style={centeredCell}>{habit.maxRepetitionsInAnInstance}</td>
              <td style={centeredCell}>{habit.maxRepetitionsInASet}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

function getLastUsedStr(timestamp: import('firebase').firestore.Timestamp) {
  return timestamp.seconds > 0 ? timestamp.toDate().toLocaleString() : 'never';
}

function getConnotationSymbol(connotation: Connotation) {
  switch (connotation) {
    case 'POSITIVE':
      return '+';
    case 'NEUTRAL':
      return '/';
    case 'NEGATIVE':
      return '-';
  }
}
