import React from 'react';

interface Props {
  name: string;
  onChange: (value: string) => void;
}

export const EditName: React.FC<Props> = ({ name, onChange }) => {
  return <input value={name} onChange={(e) => onChange(e.target.value)} />;
};
