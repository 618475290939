import { useLocation } from 'react-router-dom';

const defaultLocationState = { from: { pathname: '/' } };

interface FromLocationState {
  from: Location;
}

export function useFromLocationState() {
  const location = useLocation<FromLocationState>();
  const { from } = location.state ?? defaultLocationState;
  return from;
}
