import React, { useMemo } from 'react';
import { Area, AreaChart, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { StackedBarData } from '../AppStackedBarChart/AppStackedBarChart';
import { appStackedChartLegendFormatterFactory } from '../appStackedChartLegendFormatterFactory';
import { appStackedChartTooltipFormatterFactory } from '../appStackedChartTooltipFormatterFactory';
import { AppStackedChartYAxisTick } from '../AppStackedChartYAxisTick';

interface Props {
  data: StackedBarData;
}

export const AppStackedAreaChart: React.FC<Props> = ({ data: { dataPoints, barMetadata } }) => {
  const legendFormatter = useMemo(() => appStackedChartLegendFormatterFactory(barMetadata), [
    barMetadata,
  ]);
  const tooltipFormatter = useMemo(() => appStackedChartTooltipFormatterFactory(barMetadata), [
    barMetadata,
  ]);
  return (
    <ResponsiveContainer aspect={1}>
      <AreaChart data={dataPoints}>
        <XAxis dataKey="groupLabel" />
        <YAxis tick={AppStackedChartYAxisTick} />
        <Tooltip formatter={tooltipFormatter} labelStyle={{ color: 'black' }} />
        <Legend formatter={legendFormatter} />
        {barMetadata.map(({ property, color }) => (
          <Area dataKey={property} fill={color} key={property} stackId="1" stroke={color} />
        ))}
      </AreaChart>
    </ResponsiveContainer>
  );
};
