import React from 'react';
import { Switch, MenuItem, ListItemIcon, ListItemText, FormControlLabel } from '@material-ui/core';

import { LocalStorageKeyUseFirestoreEmulator } from '../../../utils/localStorageKeys';

const value = window.localStorage.getItem(LocalStorageKeyUseFirestoreEmulator) === 'true';

export const FirestoreEmulatorMenuItem: React.FC = () => {
  if (process.env.NODE_ENV === 'production') return null;

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const newValue = event.target.checked;
    window.localStorage.setItem(LocalStorageKeyUseFirestoreEmulator, String(newValue));
    window.location.reload();
  }
  return (
    <MenuItem>
      <ListItemIcon></ListItemIcon>
      <ListItemText>
        <FormControlLabel
          control={<Switch checked={value} onChange={handleChange} />}
          label={value ? 'Firestore Emulator Enabled' : 'Firestore Emulator Disabled'}
        />
      </ListItemText>
    </MenuItem>
  );
};
