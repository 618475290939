import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      warning: {
        color: theme.palette.warning[theme.palette.type],
      },
      neutral: {},
    }),
  { name: 'ConfirmationDialog' }
);

interface Props {
  open: boolean;
  type?: 'warning' | 'neutral';
  title?: React.ReactNode;
  content: React.ReactNode;
  onAccept?: () => void;
  onCancel?: () => void;
}

export const ConfirmationDialog: React.FC<Props> = ({
  open,
  type = 'neutral',
  title,
  content,
  onAccept,
  onCancel,
}) => {
  const classes = useStyles();
  if (type === 'warning') title = title ?? 'Warning';
  return (
    <Dialog
      aria-labelledby="confirmation-dialog-title"
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="sm"
      open={open}
    >
      <DialogTitle className={classes[type]} id="confirmation-dialog-title">
        {title}
      </DialogTitle>
      <DialogContent dividers={!!title}>{content}</DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onCancel}>
          Cancel
        </Button>
        <Button className={classes[type]} onClick={onAccept}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};
