import React, { useReducer, useMemo, useEffect, useContext, createContext } from 'react';
import type { User } from 'firebase';

import { firebaseAuth } from '../../firebase';

const initialState = {
  isInitialized: false,
  user: null as User | null,
};

type AuthState = typeof initialState;

type Action = { type: 'UPDATE_USER'; payload: { user: User | null } };

function reducer(state: AuthState, action: Action): AuthState {
  switch (action.type) {
    case 'UPDATE_USER':
      return {
        isInitialized: true,
        user: action.payload.user,
      };
    default:
      throw new Error(`Unhandled action type: ${(action as Action).type}`);
  }
}

type Value = [AuthState, React.Dispatch<Action>];

const AuthContext = createContext({} as Value);

const AuthProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value: Value = useMemo(() => [state, dispatch], [state]);

  useEffect(() => {
    return firebaseAuth.onAuthStateChanged((user) => {
      dispatch({ type: 'UPDATE_USER', payload: { user } });
    });
  }, []);

  return (
    <AuthContext.Provider value={value}>
      {state.isInitialized ? children : null}
    </AuthContext.Provider>
  );
};

function useAuth() {
  return useContext(AuthContext);
}

function signOut() {
  return firebaseAuth.signOut();
}

export { AuthProvider, useAuth, signOut };
