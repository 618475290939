import { Grid } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';

import { ChartEntity } from '../../domain/chart';
import { HabitEntity } from '../../domain/habit';
import { chartsCollection, habitsCollection, toEntityCollection } from '../../queries';
import { AddButton } from '../AddButton';

import { ChartCardSpecializedBaseProps } from './Charts/chartCardSpecializedBaseProps';
import { chartCardComponentMap } from './Charts/chartMap';
import { UpdateChart } from './UpdateChart/UpdateChart';

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        marginTop: theme.spacing(1),
      },
      addButton: {
        position: 'fixed',
        right: theme.spacing(2),
        bottom: theme.spacing(2),
        fontSize: 40,
        [theme.breakpoints.up('sm')]: {
          fontSize: 56,
        },
      },
    }),
  { name: 'StatisticsPage' }
);

export const StatisticsPage: React.FC = () => {
  const classes = useStyles();
  const [habits, setHabits] = useState<HabitEntity[]>([]);
  const [charts, setCharts] = useState<ChartEntity<unknown>[]>([]);

  const [editingChart, setEditingChart] = useState<ChartEntity<unknown> | undefined>(undefined);
  const [editingChartDialogOpen, setEditingChartDialogOpen] = useState(false);

  useEffect(() => {
    return habitsCollection().onSnapshot((querySnapshot) =>
      setHabits(toEntityCollection(querySnapshot))
    );
  }, []);

  useEffect(() => {
    return chartsCollection()
      .orderBy('order', 'asc')
      .onSnapshot((querySnapshot) => setCharts(toEntityCollection(querySnapshot)));
  }, []);

  function handleChartEditMenu(chartEntity: ChartEntity<unknown>) {
    setEditingChart(chartEntity);
    setEditingChartDialogOpen(true);
  }

  async function handleChartDeleteMenu(id: ID) {
    try {
      await chartsCollection().doc(id).delete();
    } catch (error) {
      window.alert(error.message);
    }
  }

  function handleChartAdd() {
    setEditingChart(undefined);
    setEditingChartDialogOpen(true);
  }

  return (
    <>
      <Grid className={classes.root} container spacing={1}>
        {charts.map((chartEntity) => {
          // TODO: when more card types arrive, this polymorphic behavior will be reviewed.
          const ChartCardComponent = chartCardComponentMap[chartEntity.type] as React.FC<
            ChartCardSpecializedBaseProps
          >;
          return (
            <Grid item key={chartEntity.id} md={4} sm={6} xs={12}>
              <ChartCardComponent
                chartEntity={chartEntity}
                habits={habits}
                previewMode={false}
                onDelete={handleChartDeleteMenu}
                onEdit={handleChartEditMenu}
              />
            </Grid>
          );
        })}
      </Grid>
      <AddButton className={classes.addButton} color="secondary" onClick={handleChartAdd} />
      <UpdateChart
        chart={editingChart}
        habits={habits}
        isOpen={editingChartDialogOpen}
        onClose={() => setEditingChartDialogOpen(false)}
      />
    </>
  );
};
