import { Timestamp } from '../firebase';
import { chartById } from '../queries';

export function updateChartCachedData(id: ID, cachedData: unknown) {
  const chart = chartById(id);
  return chart.update({
    'data.cachedData': cachedData,
    'data.lastUpdated': Timestamp.fromDate(new Date()),
  });
}
