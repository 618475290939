import { useTheme } from '@material-ui/core/styles';
import { useEffect, useState } from 'react';

import { ChartEntity } from '../../../domain/chart';
import { HabitEntity } from '../../../domain/habit';

import { IndependentChartData } from './IndependentChartCard/IndependentChartData';
import { computeIndependentChartData } from './IndependentChartCard/computeIndependentChartData';
import { PositiveVsNegativeChartData } from './PositiveVsNegativeChartCard/PositiveVsNegativeChartData';
import { computePositiveVsNegativeChartData } from './PositiveVsNegativeChartCard/computePositiveVsNegativeChartData';

export function useCachedDataUpdates(
  chartEntity: ChartEntity<IndependentChartData | PositiveVsNegativeChartData>,
  habits: HabitEntity[],
  previewMode: boolean
) {
  const theme = useTheme();

  const [data, setData] = useState<{
    value: typeof chartEntity.data.cachedData;
    owner: typeof chartEntity | undefined;
  }>({ value: undefined, owner: undefined });
  const chartEntityChanged = data.owner !== chartEntity;
  const result = chartEntityChanged ? undefined : data.value;

  useEffect(() => {
    let isCurrentEffect = true;

    // 1 hour threshold
    const { lastUpdated } = chartEntity.data;
    const lastUpdatedDate = lastUpdated ? lastUpdated.toDate() : null;
    const cacheDateThreshold = new Date();
    cacheDateThreshold.setHours(cacheDateThreshold.getHours() - 1);
    if (!previewMode && chartEntity.data.cachedData) {
      if (lastUpdatedDate && lastUpdatedDate >= cacheDateThreshold) {
        setData({ value: chartEntity.data.cachedData, owner: chartEntity });
        return undefined;
      }
    }

    // cached date is outdated, needs to be calculated again
    const negativeColor = theme.palette.error[theme.palette.type];
    const positiveColor = theme.palette.success[theme.palette.type];
    switch (chartEntity.type) {
      case 'Independent':
        computeIndependentChartData(chartEntity, habits, !previewMode)
          .then((freshData) => {
            if (!isCurrentEffect) return;
            setData({ value: freshData, owner: chartEntity });
          })
          .catch((error) => window.alert(error));
        break;
      case 'PositiveVsNegative':
        computePositiveVsNegativeChartData(
          chartEntity,
          habits,
          positiveColor,
          negativeColor,
          !previewMode
        )
          .then((freshData) => {
            if (!isCurrentEffect) return;
            setData({ value: freshData, owner: chartEntity });
          })
          .catch((error) => window.alert(error));
        break;
      default:
        throw new Error(
          `ChartType=${chartEntity.type} is not covered by "useCachedDataUpdates" hook`
        );
    }

    return () => {
      isCurrentEffect = false;
    };
  }, [chartEntity, habits, previewMode, theme]);

  return result;
}
