import { Chip, IconButton, Menu, MenuItem, Tooltip, useMediaQuery } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Done, FilterList } from '@material-ui/icons';
import clsx from 'clsx';
// eslint-disable-next-line import/no-unresolved -- TODO: Check why the TS definitions that live inside the package are not working.
import NestedMenuItem from 'material-ui-nested-menu-item';
import React, { useState } from 'react';

import { HabitEntity } from '../../../../../domain/habit';

const orderByProperties: { property: keyof HabitEntity; label: string }[] = [
  { property: 'name', label: 'Name' },
  { property: 'lastInstanceDate', label: 'Last Instance Date' },
];

export type ConnotationFilter = Exclude<HabitEntity['connotation'], 'NEUTRAL'> | null;

const filterProperties: {
  connotation: ConnotationFilter;
  label: string;
  shortLabel: string;
}[] = [
  { connotation: 'POSITIVE', label: 'Positive', shortLabel: 'P+' },
  { connotation: 'NEGATIVE', label: 'Negative', shortLabel: 'N-' },
];

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        display: 'inline-flex',
      },
      chipList: {
        margin: 0,
        padding: 0,
        listStyle: 'none',
        display: 'flex',
        alignItems: 'center',
      },
      chip: {
        margin: theme.spacing(0, 1),
      },
    }),
  { name: 'FilterMenu' }
);

interface Props {
  className?: string;
  order: Order;
  orderBy: keyof HabitEntity;
  onRequestSort: (orderBy: keyof HabitEntity, order?: Order) => void;
  onConnotationFilterChanged: (connotationFilter: ConnotationFilter) => void;
}

export const FilterMenu: React.FC<Props> = ({
  className,
  order,
  orderBy,
  onRequestSort,
  onConnotationFilterChanged,
}) => {
  const classes = useStyles();
  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null);
  const [connotationFilter, setConnotationFilter] = useState<ConnotationFilter>(null);
  const matches360down = useMediaQuery<Theme>((theme) => theme.breakpoints.down(360));

  function handleMenuOpen(e: React.MouseEvent<HTMLButtonElement>) {
    setAnchorElement(e.currentTarget);
  }

  function handleMenuClose() {
    setAnchorElement(null);
  }

  function handleMenuItemClick(e: React.MouseEvent<HTMLLIElement>) {
    const { order, orderBy } = e.currentTarget.dataset;
    onRequestSort(orderBy as keyof HabitEntity, order as Order);
    handleMenuClose();
  }

  function handleChipClick(e: React.MouseEvent<HTMLDivElement>) {
    const connotationClicked = e.currentTarget.dataset.connotation as ConnotationFilter;
    const newValue = connotationFilter === connotationClicked ? null : connotationClicked;
    setConnotationFilter(newValue);
    onConnotationFilterChanged(newValue);
  }

  const isMenuOpen = !!anchorElement;
  const menu = (
    <Menu
      anchorEl={anchorElement}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      autoFocus={false}
      disableAutoFocusItem
      getContentAnchorEl={null}
      open={isMenuOpen}
      variant="menu"
      onClose={handleMenuClose}
    >
      {orderByProperties.map((entry) => (
        <NestedMenuItem
          id={entry.property}
          key={entry.property}
          label={entry.label}
          parentMenuOpen={isMenuOpen}
          selected={entry.property === orderBy}
        >
          <MenuItem
            data-order="asc"
            data-order-by={entry.property}
            selected={entry.property === orderBy && order === 'asc'}
            onClick={handleMenuItemClick}
          >
            Ascending
          </MenuItem>
          <MenuItem
            data-order="desc"
            data-order-by={entry.property}
            selected={entry.property === orderBy && order === 'desc'}
            onClick={handleMenuItemClick}
          >
            Descending
          </MenuItem>
        </NestedMenuItem>
      ))}
    </Menu>
  );

  return (
    <div className={clsx(classes.root, className)}>
      <ul className={classes.chipList}>
        {filterProperties.map((filter) => (
          <li key={filter.label}>
            <Tooltip title={`Filter ${filter.label}`}>
              <Chip
                className={classes.chip}
                clickable
                data-connotation={filter.connotation}
                icon={filter.connotation === connotationFilter ? <Done /> : undefined}
                label={matches360down ? filter.shortLabel : filter.label}
                size="small"
                onClick={handleChipClick}
              />
            </Tooltip>
          </li>
        ))}
      </ul>
      <Tooltip title="Sort list">
        <IconButton aria-label="sort list" onClick={handleMenuOpen}>
          <FilterList />
        </IconButton>
      </Tooltip>
      {menu}
    </div>
  );
};
