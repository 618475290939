import { pad } from './pad';

/** Receives hh:mm and returns the total amount in milliseconds */
function durationStringToSeconds(duration: string) {
  if (!isValidDurationString(duration)) throw new Error('Invalid duration format, expected hh:mm');
  const hours = Number(duration.substring(0, 2));
  const minutes = Number(duration.substring(3));
  // minutes * 60 = seconds
  return (hours * 60 + minutes) * 60;
}

function secondsToDurationString(seconds: number) {
  let minutes = Math.ceil(seconds / 60);
  const hours = Math.floor(minutes / 60);
  minutes = minutes % 60;
  return `${pad(hours, 2)}:${pad(minutes, 2)}`;
}

/** Receives seconds and returns a label that in the form 1d3h10m */
function secondsToDurationLabel(seconds: number) {
  let minutes = Math.ceil(seconds / 60);
  const hours = Math.floor(minutes / 60);
  // const days = Math.floor(hours / 24);
  minutes = minutes % 60;
  // hours = hours % 24;
  let label = '';
  // if (days > 0) label = label + `${days}d`;
  if (hours > 0) label = label + `${hours}h`;
  if (minutes > 0) label = label + `${minutes}m`;
  return label.length ? label : `${seconds}s`;
}

/** Validates hh:mm format */
function isValidDurationString(duration: string) {
  return /^\d\d:\d\d$/.test(duration);
}

export {
  durationStringToSeconds,
  secondsToDurationString,
  secondsToDurationLabel,
  isValidDurationString,
};
