import { IndependentChartCard } from './IndependentChartCard/IndependentChartCard';
import { PositiveVsNegativeChartCard } from './PositiveVsNegativeChartCard/PositiveVsNegativeChartCard';
import { AppPieChart } from './SharedCharts/AppPieChart/AppPieChart';
import { AppStackedAreaChart } from './SharedCharts/AppStackedAreaChart/AppStackedAreaChart';
import { AppStackedBarChart } from './SharedCharts/AppStackedBarChart/AppStackedBarChart';

const chartCardComponentMap = {
  PositiveVsNegative: PositiveVsNegativeChartCard,
  Independent: IndependentChartCard,
};

const chartUiVariantComponentMap = {
  Pie: {
    id: 'Pie' as const,
    label: 'Pie',
    component: AppPieChart,
  },
  StackedBars: {
    id: 'StackedBars' as const,
    label: 'Stacked Bars',
    component: AppStackedBarChart,
  },
  StackedAreas: {
    id: 'StackedAreas' as const,
    label: 'Stacked Areas',
    component: AppStackedAreaChart,
  },
};

export { chartCardComponentMap, chartUiVariantComponentMap };
