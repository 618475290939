import React from 'react';

import { ChartEntity } from '../../../../domain/chart';
import { dateToElapsedTime } from '../../../../utils/datetime';
import { FilterSummary } from '../../FilterSummary';
import { ChartCard } from '../ChartCard';
import { ChartCardSpecializedBaseProps } from '../chartCardSpecializedBaseProps';
import { chartUiVariantComponentMap } from '../chartMap';
import { useCachedDataUpdates } from '../useCachedDataUpdates';

import { PositiveVsNegativeChartData } from './PositiveVsNegativeChartData';

interface Props extends ChartCardSpecializedBaseProps {
  chartEntity: ChartEntity<PositiveVsNegativeChartData>;
}

export const PositiveVsNegativeChartCard: React.FC<Props> = ({
  habits,
  chartEntity,
  previewMode,
  ...chartCardProps
}) => {
  const data = useCachedDataUpdates(chartEntity, habits, previewMode);

  const ChartUIComponent = chartUiVariantComponentMap[chartEntity.variant].component;

  if (!data) return null;

  return (
    <ChartCard
      {...chartCardProps}
      chartEntity={chartEntity}
      chartSummaryUI={
        <FilterSummary filteredHabits={chartEntity.data.selectedHabits} habits={habits} />
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: use better types here?
      chartUI={<ChartUIComponent data={data as any} />}
      subheader={
        previewMode
          ? undefined
          : `Updated: ${dateToElapsedTime(chartEntity.data.lastUpdated.toDate())}`
      }
    />
  );
};
