import React, { useMemo } from 'react';
import { Legend, Pie, PieChart, ResponsiveContainer } from 'recharts';

import { PieSectorLabel } from './PieSectorLabel';

export type PieData = PieSectorData[];

interface PieSectorData {
  label: string;
  subLabel: string;
  value: number;
  color: string;
}

interface Props {
  data: PieData;
}

export const AppPieChart: React.FC<Props> = ({ data }) => {
  const pieData = useMemo(() => {
    return data.map(({ label, subLabel, value, color }) => {
      return {
        label,
        subLabel,
        value,
        fill: color,
        stroke: color,
      };
    });
  }, [data]);

  const inlineLegend = pieData.length <= 2;

  return (
    <ResponsiveContainer aspect={1}>
      <PieChart>
        <Pie
          data={pieData}
          dataKey="value"
          label={inlineLegend ? PieSectorLabel : false}
          labelLine={false}
        />
        {inlineLegend ? null : <Legend formatter={legendFormatter} />}
      </PieChart>
    </ResponsiveContainer>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- Rechart types are not good
function legendFormatter(value?: unknown, entry?: any) {
  const label = entry?.payload?.label ?? 'unknown';
  return label;
}
