import { firebaseFirestore } from '../firebase';
import { habitInstancesCollection, habitsCollection } from '../queries';

// TODO: move this to a cloud function
// the batch might be too big and running different batches in the client will not work if the app is terminated.

export async function deleteHabitAndInstances(deletedHabitId: ID) {
  const batch = firebaseFirestore.batch();

  const instances = await habitInstancesCollection().where('habitId', '==', deletedHabitId).get();

  if (instances.size >= 500) {
    throw new Error(`Deleting a habit with more than 499 instances. This will fail on the client.`);
  }

  instances.forEach((instance) => {
    batch.delete(instance.ref);
  });

  batch.delete(habitsCollection().doc(deletedHabitId));

  await batch.commit();
}
