import { ChartEntity } from '../../../../domain/chart';
import { HabitEntity } from '../../../../domain/habit';
import { InstanceEntity } from '../../../../domain/instance';
import { TimeRange } from '../../../../domain/weakEntities/timeRange';
import { updateChartCachedData } from '../../../../mutations/updateChartCachedData';
import { habitInstancesByTimeRange, toEntityCollection } from '../../../../queries';
import { secondsToDurationLabel } from '../../../../utils/duration';
import { filterHabits } from '../../../../utils/filterHabits';
import { monthKeys } from '../../../../utils/monthKeys';
import { PieData } from '../SharedCharts/AppPieChart/AppPieChart';
import { StackedBarData } from '../SharedCharts/AppStackedBarChart/AppStackedBarChart';

import { PositiveVsNegativeChartData } from './PositiveVsNegativeChartData';

export async function computePositiveVsNegativeChartData(
  chartEntity: ChartEntity<PositiveVsNegativeChartData>,
  habits: HabitEntity[],
  positiveColor: string,
  negativeColor: string,
  shouldUpdate: boolean
): Promise<PieData | StackedBarData> {
  const cachedData = await computeCachedData(chartEntity, habits, positiveColor, negativeColor);

  if (shouldUpdate) {
    await updateChartCachedData(chartEntity.id, cachedData);
  }

  return cachedData;
}

function computeCachedData(
  chartEntity: ChartEntity<PositiveVsNegativeChartData>,
  habits: HabitEntity[],
  positiveColor: string,
  negativeColor: string
): Promise<PieData | StackedBarData> {
  switch (chartEntity.variant) {
    case 'Pie':
      return Promise.resolve(computePie(chartEntity, habits, positiveColor, negativeColor));
    case 'StackedBars':
    case 'StackedAreas':
      return computeStackedBars(
        chartEntity.data.timeRange,
        chartEntity.data.selectedHabits,
        positiveColor,
        negativeColor
      );
    default:
      throw new Error(
        `Variant=${chartEntity.variant} is not covered by "computeCachedData" function`
      );
  }
}

async function computePie(
  chartEntity: ChartEntity<PositiveVsNegativeChartData>,
  habits: HabitEntity[],
  positiveColor: string,
  negativeColor: string
): Promise<PieData> {
  if (chartEntity.data.timeRange.type === 'all') {
    return computePieFromHabits(
      chartEntity.data.selectedHabits,
      habits,
      positiveColor,
      negativeColor
    );
  }
  return computePieFromInstances(
    chartEntity.data.timeRange,
    chartEntity.data.selectedHabits,
    positiveColor,
    negativeColor
  );
}

function computePieFromHabits(
  selectedHabits: ID[],
  habits: HabitEntity[],
  positiveColor: string,
  negativeColor: string
): PieData {
  const filteredHabits = filterHabits(habits, selectedHabits);
  const time = { POSITIVE: 0, NEGATIVE: 0 };
  filteredHabits.forEach((habit) => {
    if (habit.connotation === 'NEUTRAL') return;
    time[habit.connotation] += habit.totalInstanceTimeSeconds ?? 0;
  });
  const total = time.POSITIVE + time.NEGATIVE;
  const positivePercent = total > 0 ? Math.round((time.POSITIVE / total) * 100) : 0;
  const negativePercent = total > 0 ? Math.round((time.NEGATIVE / total) * 100) : 0;
  return [
    {
      label: 'Positive',
      subLabel: `${secondsToDurationLabel(time.POSITIVE)} (${positivePercent}%)`,
      value: time.POSITIVE,
      color: positiveColor,
    },
    {
      label: 'Negative',
      subLabel: `${secondsToDurationLabel(time.NEGATIVE)} (${negativePercent}%)`,
      value: time.NEGATIVE,
      color: negativeColor,
    },
  ];
}

async function computePieFromInstances(
  timeRange: TimeRange,
  selectedHabits: ID[],
  positiveColor: string,
  negativeColor: string
): Promise<PieData> {
  const instances = toEntityCollection<InstanceEntity>(
    await habitInstancesByTimeRange(timeRange).get()
  );
  const selectedHabitsSet = new Set(selectedHabits);
  const time = { POSITIVE: 0, NEGATIVE: 0 };
  instances.forEach((instance) => {
    if (instance.habitConnotation === 'NEUTRAL') return;
    if (!selectedHabitsSet.has(instance.habitId)) return;
    time[instance.habitConnotation] += instance.durationSeconds ?? 0;
  });
  const total = time.POSITIVE + time.NEGATIVE;
  const positivePercent = total > 0 ? Math.round((time.POSITIVE / total) * 100) : 0;
  const negativePercent = total > 0 ? Math.round((time.NEGATIVE / total) * 100) : 0;
  return [
    {
      label: 'Positive',
      subLabel: `${secondsToDurationLabel(time.POSITIVE)} (${positivePercent}%)`,
      value: time.POSITIVE,
      color: positiveColor,
    },
    {
      label: 'Negative',
      subLabel: `${secondsToDurationLabel(time.NEGATIVE)} (${negativePercent}%)`,
      value: time.NEGATIVE,
      color: negativeColor,
    },
  ];
}

async function computeStackedBars(
  timeRange: TimeRange,
  selectedHabits: ID[],
  positiveColor: string,
  negativeColor: string
): Promise<StackedBarData> {
  const instances = toEntityCollection<InstanceEntity>(
    await habitInstancesByTimeRange(timeRange).get()
  );

  const selectedHabitsSet = new Set(selectedHabits);
  const dataByDay = new Map<
    string,
    { POSITIVE: number; NEGATIVE: number; sortKey: number; groupLabel: string }
  >();

  instances.forEach((instance) => {
    if (instance.habitConnotation === 'NEUTRAL') return;
    if (!instance.durationSeconds) return;
    if (!selectedHabitsSet.has(instance.habitId)) return;
    const startDate = instance.startDate.toDate();
    const day = startDate.getDate();
    const month = startDate.getMonth();
    const key = `${monthKeys[month]}, ${day}`;
    const keyExists = dataByDay.has(key);
    const data = keyExists
      ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- value is guaranteed to exist
        dataByDay.get(key)!
      : { POSITIVE: 0, NEGATIVE: 0, sortKey: instance.startDate.seconds, groupLabel: key };
    data[instance.habitConnotation] += instance.durationSeconds;
    if (!keyExists) dataByDay.set(key, data);
  });

  const rawData = Array.from(dataByDay.values());
  rawData.sort((x, y) => x.sortKey - y.sortKey);

  const dataPoints = rawData.map(({ POSITIVE, NEGATIVE, groupLabel }) => ({
    positive: POSITIVE,
    negative: NEGATIVE,
    groupLabel,
  }));

  const barMetadata = [
    { property: 'positive', color: positiveColor, label: 'Positive' },
    { property: 'negative', color: negativeColor, label: 'Negative' },
  ];

  return { barMetadata, dataPoints };
}
