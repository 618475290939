import React, { useState } from 'react';
import {
  AppBar,
  Toolbar,
  IconButton,
  Menu,
  MenuItem,
  Avatar,
  ListItemText,
  ListItemIcon,
  Switch,
  FormControlLabel,
} from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  Menu as MenuIcon,
  AccountCircle,
  ExitToApp,
  Brightness4,
  BrightnessHigh,
} from '@material-ui/icons';
import { NavLink } from 'react-router-dom';

import { useAuth, signOut } from '../../auth/AuthContext';
import { useAppTheme } from '../../App/AppTheme';

import { FirestoreEmulatorMenuItem } from './FirestoreEmulatorMenuItem';

type TopBarMenuType = 'navigationMenu' | 'profileMenu';

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      grow: {
        flexGrow: 1,
      },
      menuButton: {
        marginRight: theme.spacing(2),
      },
      avatar: {
        height: theme.spacing(4),
        width: theme.spacing(4),
      },
    }),
  { name: 'TopBarRenderFunction' }
);

const TopBarRenderFunction: React.ForwardRefRenderFunction<HTMLHeadElement> = (_, ref) => {
  const classes = useStyles();
  const [menuAnchorElements, setMenuAnchorElements] = useState<{
    navigationMenu: null | HTMLElement;
    profileMenu: null | HTMLElement;
  }>({ navigationMenu: null, profileMenu: null });
  const [{ user }] = useAuth();
  const { themeKey, setTheme } = useAppTheme();

  function isMenuOpen(type: TopBarMenuType) {
    return !!menuAnchorElements[type];
  }

  function handleMenuOpen(event: React.MouseEvent<HTMLElement>) {
    const menuType = event.currentTarget.id as TopBarMenuType;
    setMenuAnchorElements((value) => ({ ...value, [menuType]: event.currentTarget }));
  }

  function handleMenuClose() {
    setMenuAnchorElements({ navigationMenu: null, profileMenu: null });
  }

  const navigationMenuId = 'top-bar-navigation-menu';
  const profileMenuId = 'top-bar-profile-menu';

  const navigationMenu = (
    <Menu
      anchorEl={menuAnchorElements.navigationMenu}
      id={navigationMenuId}
      open={isMenuOpen('navigationMenu')}
      onClose={handleMenuClose}
    >
      <MenuItem component={NavLink} exact to="/" onClick={handleMenuClose}>
        Home
      </MenuItem>
      <MenuItem component={NavLink} to="/stats" onClick={handleMenuClose}>
        Stats
      </MenuItem>
      <MenuItem component={NavLink} to="/habits" onClick={handleMenuClose}>
        Habits
      </MenuItem>
      <MenuItem component={NavLink} to="/habits-legacy" onClick={handleMenuClose}>
        Habits (Legacy)
      </MenuItem>
      <MenuItem component={NavLink} to="/instances-legacy" onClick={handleMenuClose}>
        Instances (Legacy)
      </MenuItem>
    </Menu>
  );

  const profileMenu = (
    <Menu
      anchorEl={menuAnchorElements.profileMenu}
      id={profileMenuId}
      open={isMenuOpen('profileMenu')}
      onClose={handleMenuClose}
    >
      <MenuItem disabled onClick={handleMenuClose}>
        <ListItemIcon>
          <AccountCircle fontSize="small" />
        </ListItemIcon>
        <ListItemText>{user?.email}</ListItemText>
      </MenuItem>
      <MenuItem>
        <ListItemIcon>
          {themeKey === 'light' ? (
            <BrightnessHigh fontSize="small" />
          ) : (
            <Brightness4 fontSize="small" />
          )}
        </ListItemIcon>
        <ListItemText>
          <FormControlLabel
            control={
              <Switch
                checked={themeKey === 'dark'}
                onChange={(e) => setTheme(e.target.checked ? 'dark' : 'light')}
              />
            }
            label="Theme"
          />
        </ListItemText>
      </MenuItem>
      <FirestoreEmulatorMenuItem />
      <MenuItem onClick={handleMenuClose}>
        <ListItemIcon>
          <ExitToApp fontSize="small" />
        </ListItemIcon>
        <ListItemText onClick={signOut}>Sign out</ListItemText>
      </MenuItem>
    </Menu>
  );

  return (
    <>
      <AppBar position="fixed" ref={ref}>
        <Toolbar>
          <IconButton
            aria-controls={navigationMenuId}
            aria-haspopup="true"
            aria-label="opens navigation"
            className={classes.menuButton}
            color="inherit"
            edge="start"
            id="navigationMenu"
            onClick={handleMenuOpen}
          >
            <MenuIcon />
          </IconButton>
          <div className={classes.grow} />
          <IconButton
            aria-controls={profileMenuId}
            aria-haspopup="true"
            aria-label="current user profile"
            color="inherit"
            edge="end"
            id="profileMenu"
            onClick={handleMenuOpen}
          >
            <Avatar alt="Profile Menu" className={classes.avatar} src={user?.photoURL ?? ''}>
              <AccountCircle />
            </Avatar>
          </IconButton>
        </Toolbar>
      </AppBar>
      <Toolbar />
      {navigationMenu}
      {profileMenu}
    </>
  );
};

export const TopBar = React.forwardRef(TopBarRenderFunction);
