import 'firebase/firestore';
import app from 'firebase/app';

import { LocalStorageKeyUseFirestoreEmulator } from '../utils/localStorageKeys';

import { firebaseApp } from './app';

const firebaseFirestore = firebaseApp.firestore();

const Timestamp = app.firestore.Timestamp;

export { firebaseFirestore, Timestamp };

if (process.env.NODE_ENV !== 'production') {
  const useFirestoreEmulator = Boolean(
    window.localStorage.getItem(LocalStorageKeyUseFirestoreEmulator) === 'true'
  );
  const isTestEnv = process.env.NODE_ENV === 'test';

  if (useFirestoreEmulator || isTestEnv) {
    if (window.location.hostname === 'localhost') {
      console.info('%c Using firestore emulator.', 'color: green');
      firebaseFirestore.settings({
        host: 'localhost:8080',
        ssl: false,
      });
    }
  }
}
