import { RouteProps, Route, Redirect } from 'react-router-dom';
import React from 'react';

import { loginPath } from '../../routes';

import { useAuth } from './AuthContext';

export const PrivateRoute: React.FC<RouteProps> = ({ children, ...props }) => {
  const [{ user }] = useAuth();
  return (
    <Route
      {...props}
      render={({ location }) => {
        if (user) {
          return children;
        }

        return (
          <Redirect
            to={{
              pathname: loginPath,
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
};
