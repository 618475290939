import React from 'react';

import { secondsToDurationLabel } from '../../../../utils/duration';

export const AppStackedChartYAxisTick: React.FC<{
  x: number;
  y: number;
  payload: { value: number };
}> = ({ x, y, payload }) => {
  return (
    // TODO: review fill and text anchor props
    <text dy={16} fill="#666" textAnchor="end" x={x} y={y}>
      {secondsToDurationLabel(payload.value)}
    </text>
  );
};
