import React, { useEffect, useState } from 'react';
import { ButtonProps, Button, CircularProgress } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles(
  () =>
    createStyles({
      root: {
        position: 'relative',
      },
      circularProgress: {
        position: 'absolute',
      },
    }),
  { name: 'LoadingButton' }
);

interface Props {
  isLoading: boolean;
}

// FIXME: When using the ref-forwarding API props are not typed anymore

// export const LoadingButton = React.forwardRef(function LoadingButton<C extends React.ElementType>(
//   { isLoading, children, ...props }: Props & ButtonProps<C, { component?: C }>,
//   ref: any
// ) {
//   return (
//     <Button disabled={isLoading} ref={ref} {...props}>
//       {children}
//       {isLoading ? <CircularProgress size={24} /> : undefined}
//     </Button>
//   );
// });

export function LoadingButton<C extends React.ElementType>({
  isLoading,
  children,
  className,
  ...props
}: Props & ButtonProps<C, { component?: C }>) {
  const classes = useStyles();
  const [showLoadingIndicator, setShowLoadingIndicator] = useState(false);

  useEffect(() => {
    if (isLoading) {
      // Delay showing the loading indicator because of
      // https://material-ui.com/components/progress/#delaying-appearance
      const timer = window.setTimeout(() => {
        setShowLoadingIndicator(true);
      }, 500);
      return () => {
        window.clearTimeout(timer);
      };
    } else {
      setShowLoadingIndicator(false);
    }
  }, [isLoading]);

  return (
    <Button {...props} className={clsx(classes.root, className)} disabled={isLoading}>
      {children}
      {showLoadingIndicator ? (
        <CircularProgress className={classes.circularProgress} size={24} />
      ) : undefined}
    </Button>
  );
}
