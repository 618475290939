import React, { useEffect, useState, useRef } from 'react';

import type { HabitEntity } from '../../domain/habit';
import { habitsCollection, toEntity } from '../../queries';
import { Form, FormContext } from '../Form';
import { InstanceInput } from '../../domain/instance';
import {
  dateToDatetimeLocal,
  isValidDatetimeLocal,
  datetimeLocalToDate,
  datetimeLocalToTimestamp,
} from '../../utils/datetime';
import {
  isValidDurationString,
  durationStringToSeconds,
  secondsToDurationString,
} from '../../utils/duration';
import { saveInstanceAndUpdateHabit } from '../../mutations/saveInstanceAndUpdateHabit';
import { logAnalyticsException } from '../../analytics';

interface FormValues {
  habitId: string;
  startDate: string;
  endDate: string;
  duration: string;
  sets: string;
  notes: string;
}

export const AddInstance: React.FC = () => {
  const [habits, setHabits] = useState<HabitEntity[]>([]);
  const formContext = useRef<FormContext<FormValues>>(null);

  useEffect(() => {
    return habitsCollection()
      .orderBy('lastInstanceDate', 'desc')
      .onSnapshot((querySnapshot) =>
        setHabits(
          querySnapshot.docs.map((queryDocumentSnapshot) =>
            toEntity<HabitEntity>(queryDocumentSnapshot)
          )
        )
      );
  }, []);

  if (!habits.length) return null;

  async function handleSubmit(values: FormValues) {
    const habit = habits.find((habit) => habit.id === values.habitId);
    if (!habit) {
      window.alert('Habit does not exist');
      return;
    }
    const habitInstanceInput: InstanceInput = {
      habitId: habit.id,
      habitName: habit.name,
      habitConnotation: habit.connotation,
      startDate: datetimeLocalToTimestamp(values.startDate),
    };
    if (values.endDate) {
      habitInstanceInput.endDate = datetimeLocalToTimestamp(values.endDate);
    }
    if (values.duration) {
      habitInstanceInput.durationSeconds = durationStringToSeconds(values.duration);
    }
    if (values.sets) {
      habitInstanceInput.sets = values.sets;
      const setsArray = values.sets.split(',').map(Number);
      habitInstanceInput.setCount = setsArray.length;
      habitInstanceInput.setRepetitionsCount = setsArray.reduce(
        (sum, currentRepetition) => sum + currentRepetition,
        0
      );
    }

    try {
      await saveInstanceAndUpdateHabit(habitInstanceInput);
      formContext.current?.reset();
    } catch (error) {
      logAnalyticsException(
        error,
        `The call to saveInstanceAndUpdateHabit failed. The habit's statistics could have invalid values.`,
        { habitId: habitInstanceInput.habitId }
      );
      window.alert(error.message);
    }
  }

  function handleChange(key: keyof FormValues, value: unknown) {
    // Keep duration, start-time and end-time in sync
    if (key === 'duration') {
      // Clear endDate
      if (!isValidDurationString(value as string)) {
        formContext.current?.setValue('endDate', '');
        return;
      }
      const seconds = durationStringToSeconds(value as string);
      const date = formContext.current?.values.startDate
        ? datetimeLocalToDate(formContext.current?.values.startDate)
        : getInitialFormDate();
      date.setSeconds(date.getSeconds() + seconds);
      formContext.current?.setValue('endDate', dateToDatetimeLocal(date));
    } else if (key === 'startDate') {
      // Clear duration
      if (!isValidDatetimeLocal(value as string)) {
        formContext.current?.setValue('duration', '');
        return;
      }
      if (!formContext.current?.values.endDate) return;
      const endDate = datetimeLocalToDate(formContext.current?.values.endDate);
      const startDate = datetimeLocalToDate(value as string);
      const durationSeconds = Math.ceil((endDate.valueOf() - startDate.valueOf()) / 1000);
      formContext.current?.setValue('duration', secondsToDurationString(durationSeconds));
    } else if (key === 'endDate') {
      // Clear duration
      if (!isValidDatetimeLocal(value as string)) {
        formContext.current?.setValue('duration', '');
        return;
      }
      const endDate = datetimeLocalToDate(value as string);
      if (!formContext.current?.values.startDate) return;
      const startDate = datetimeLocalToDate(formContext.current?.values.startDate);
      const durationSeconds = Math.ceil((endDate.valueOf() - startDate.valueOf()) / 1000);
      formContext.current?.setValue('duration', secondsToDurationString(durationSeconds));
    }
  }

  function clearEndDateAndDuration() {
    formContext.current?.setValue('endDate', '');
    formContext.current?.setValue('duration', '');
  }

  function handleStartNow() {
    const value = dateToDatetimeLocal(new Date());
    formContext.current?.setValue('startDate', value);
    handleChange('startDate', value);
  }

  function handleEndNow() {
    const value = dateToDatetimeLocal(new Date());
    formContext.current?.setValue('endDate', value);
    handleChange('endDate', value);
  }

  return (
    <Form<FormValues>
      contextRef={formContext}
      initialValues={{
        habitId: habits[0].id,
        startDate: dateToDatetimeLocal(getInitialFormDate()),
        endDate: '',
        duration: '',
        sets: '',
        notes: '',
      }}
      onChange={handleChange}
      onSubmit={handleSubmit}
    >
      {(context) => (
        <fieldset>
          <legend>Add Instance</legend>
          <label htmlFor="habitId">Habit*</label>
          <select
            id="habitId"
            name="habitId"
            value={context.values.habitId}
            onChange={context.onChange}
          >
            {habits.map(({ id, name, connotation }) => (
              <option key={id} value={id}>
                {name} - {connotation}
              </option>
            ))}
          </select>
          <br />
          <label htmlFor="startDate">Start date*</label>
          <input
            id="startDate"
            name="startDate"
            type="datetime-local"
            value={context.values.startDate}
            onChange={context.onChange}
          />
          <button type="button" onClick={handleStartNow}>
            start now
          </button>
          <br />
          <label htmlFor="endDate">End date</label>
          <input
            id="endDate"
            name="endDate"
            type="datetime-local"
            value={context.values.endDate}
            onChange={context.onChange}
          />
          <button type="button" onClick={handleEndNow}>
            end now
          </button>

          <button type="button" onClick={clearEndDateAndDuration}>
            clear
          </button>
          <br />
          <label htmlFor="duration">Duration</label>
          <input
            id="duration"
            name="duration"
            pattern="\d\d:\d\d|^$"
            placeholder="hh:mm"
            value={context.values.duration}
            onChange={context.onChange}
          />
          <button type="button" onClick={clearEndDateAndDuration}>
            clear
          </button>
          <br />
          <label htmlFor="sets">Sets</label>
          <input
            id="sets"
            name="sets"
            pattern="(\d+)(,(\d+))*"
            placeholder="1,2,3,4"
            value={context.values.sets}
            onChange={context.onChange}
          />
          <br />
          <label htmlFor="notes">Notes</label>
          <textarea
            id="notes"
            name="notes"
            placeholder="When logging a habit you might to record a comment for that particular instance"
            rows={3}
            value={context.values.notes}
            onChange={context.onChange}
          />
          <br />
          <button>Save</button>
        </fieldset>
      )}
    </Form>
  );
};

/** A good default is rounding to the previous exact hour */
function getInitialFormDate() {
  const date = new Date();
  date.setHours(date.getHours() - 1);
  date.setMinutes(0);
  return date;
}
