import React, { useMemo } from 'react';
import { Typography } from '@material-ui/core';

import { HabitEntity } from '../../domain/habit';

interface Props {
  habits: HabitEntity[];
  filteredHabits: ID[];
}

export const FilterSummary: React.FC<Props> = ({ habits, filteredHabits }) => {
  const namedFilters = useMemo(() => {
    const existingHabits = new Set(filteredHabits);
    return habits.filter((habit) => existingHabits.has(habit.id)).map((habit) => habit.name);
  }, [habits, filteredHabits]);

  return (
    <div>
      <Typography variant="h6">Filters:</Typography>
      <pre>{JSON.stringify(namedFilters, null, 4)}</pre>
    </div>
  );
};
