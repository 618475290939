import {
  AppBar,
  Container,
  Dialog,
  DialogContent,
  IconButton,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Close } from '@material-ui/icons';
import React, { useMemo } from 'react';

import { ChartEntity, ChartInput } from '../../../domain/chart';
import { HabitEntity } from '../../../domain/habit';
import { TransitionUp } from '../../TransitionUp';
import { PositiveVsNegativeChartData } from '../Charts/PositiveVsNegativeChartCard/PositiveVsNegativeChartData';
import { hasId } from '../../../utils/hasId';
import { chartsCollection } from '../../../queries';
import { Timestamp } from '../../../firebase';

import { Steps } from './Steps/Steps';

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      dialogBar: {
        position: 'relative',
      },
      dialogBarTitle: {
        marginLeft: theme.spacing(2),
        flex: 1,
      },
      dialogContent: {
        [theme.breakpoints.down('sm')]: {
          padding: theme.spacing(1),
        },
      },
      steps: {
        [theme.breakpoints.down('sm')]: {
          padding: 0,
        },
      },
    }),
  { name: 'UpdateChart' }
);

interface Props {
  isOpen: boolean;
  habits: HabitEntity[];
  /* If no chart is provided then the a default chart will be created */
  chart?: ChartEntity<unknown>;
  onClose?: () => void;
}

/** Used to create or edit charts depending of the `chart` prop value */
export const UpdateChart: React.FC<Props> = ({ isOpen, habits, chart, onClose }) => {
  const classes = useStyles();

  const isEditing = !!chart;

  const editingChart = useMemo(() => chart ?? getNewChartTemplate(), [chart]);

  const dialogTitle = isEditing ? 'Edit Chart' : 'Add Chart';

  async function handleComplete(completedChart: ChartInput<unknown>) {
    // save
    try {
      if (hasId(completedChart)) {
        await chartsCollection().doc(completedChart.id).update(completedChart);
      } else {
        await chartsCollection().add(completedChart);
      }
      onClose && onClose();
    } catch (error) {
      window.alert(error.message);
    }
  }

  return (
    <Dialog
      TransitionComponent={TransitionUp}
      aria-labelledby="add-chart-dialog-title"
      fullScreen
      open={isOpen}
      onClose={onClose}
    >
      <AppBar className={classes.dialogBar}>
        <Toolbar>
          <IconButton aria-label="close" edge="start" onClick={onClose}>
            <Close />
          </IconButton>
          <Typography className={classes.dialogBarTitle} id="add-chart-dialog-title" variant="h6">
            {dialogTitle}
          </Typography>
        </Toolbar>
      </AppBar>
      <DialogContent className={classes.dialogContent}>
        <Container disableGutters maxWidth="sm">
          <Steps
            chart={editingChart as ChartEntity<unknown>}
            className={classes.steps}
            habits={habits}
            onFinalStepComplete={handleComplete}
          />
        </Container>
      </DialogContent>
    </Dialog>
  );
};

function getNewChartTemplate(): ChartInput<PositiveVsNegativeChartData> {
  // TODO: this should be specialized for each TData in the future.
  return {
    type: 'PositiveVsNegative',
    variant: 'Pie',
    title: `(Unsaved) Positive vs Negative ${Math.random().toString().slice(2).substr(0, 3)}`,
    order: -1,
    data: {
      selectedHabits: [],
      timeRange: {
        type: 'lastWeek',
      },
      lastUpdated: Timestamp.fromMillis(0),
    },
  };
}
