import { Timestamp } from '../firebase';

import { pad } from './pad';

/** Returns yyyy-MM-ddThh:mm */
function dateToDatetimeLocal(date: Date) {
  const year = pad(date.getFullYear(), 4);
  const month = pad(date.getMonth() + 1, 2);
  const day = pad(date.getDate(), 2);
  const hours = pad(date.getHours(), 2);
  const minutes = pad(date.getMinutes(), 2);
  return `${year}-${month}-${day}T${hours}:${minutes}`;
}

function dateToTimestamp(date: Date) {
  return Timestamp.fromDate(date);
}

/** Returns 1 min ago, 1 hour ago, 2 days ago, etc */
function dateToElapsedTime(date: Date) {
  const seconds = Math.floor((new Date().getTime() - date.getTime()) / 1000);
  if (seconds < 60) {
    return 'just now';
  }
  const minutes = Math.floor(seconds / 60);
  if (minutes < 60) {
    return `${minutes} minute${minutes === 1 ? '' : 's'} ago`;
  }
  const hours = Math.floor(minutes / 60);
  if (hours < 24) {
    return `${hours} hour${hours === 1 ? '' : 's'} ago`;
  }
  const days = Math.floor(hours / 24);
  return `${days} day${days === 1 ? '' : 's'} ago`;
}

function datetimeLocalToDate(datetimeLocal: string) {
  const v = datetimeLocal.split(/[-:.T]/).map(Number);
  return new Date(v[0], (v[1] ?? 1) - 1, v[2] ?? 0, v[3] ?? 0, v[4] ?? 0, v[5] ?? 0, v[6] ?? 0);
}

function datetimeLocalToTimestamp(datetimeLocal: string) {
  return Timestamp.fromDate(datetimeLocalToDate(datetimeLocal));
}

/** Validates yyyy-MM-ddThh:mm */
function isValidDatetimeLocal(date: string) {
  return /^\d\d\d\d-\d\d-\d\dT\d\d:\d\d(:\d\d(.\d\d\d)?)?$/.test(date);
}

export {
  dateToDatetimeLocal,
  dateToTimestamp,
  datetimeLocalToDate,
  datetimeLocalToTimestamp,
  dateToElapsedTime,
  isValidDatetimeLocal,
};
