import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';

import { useAuth } from './AuthContext';
import { useFromLocationState } from './useFromLocationState';

/**
 * Will redirect (replace current route with either the default route or the route in the location state) when the user is already authenticated.

 * This is useful for pages that doesn't make sense to visit when authenticated.

 * Examples:
 * - /login
*/
export function useAuthReplace() {
  const history = useHistory();
  const fromLocationState = useFromLocationState();
  const [{ user }] = useAuth();

  useEffect(() => {
    if (user) history.replace(fromLocationState);
  }, [user, fromLocationState, history]);
}
