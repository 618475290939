import React from 'react';
import { Switch, Route, BrowserRouter } from 'react-router-dom';

import { loginPath, rootPath } from '../../routes';
import { LoginPage } from '../LoginPage';
import { PrivateRoute } from '../auth/PrivateRoute';
import { AuthProvider } from '../auth/AuthContext';
import { MainLayout } from '../layout/MainLayout';
import { initializeAnalytics } from '../../analytics';

import { AppTheme } from './AppTheme';

initializeAnalytics();

export const App: React.FC = () => {
  return (
    <AppTheme>
      <BrowserRouter>
        <AuthProvider>
          <Switch>
            <Route path={loginPath}>
              <LoginPage />
            </Route>
            <PrivateRoute path={rootPath}>
              <MainLayout />
            </PrivateRoute>
          </Switch>
        </AuthProvider>
      </BrowserRouter>
    </AppTheme>
  );
};
