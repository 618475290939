import React, { useReducer, useCallback, useImperativeHandle } from 'react';

interface State<TValues> {
  values: TValues;
}

export interface FormContext<TValue> extends State<TValue> {
  reset: () => void;
  setValue: (key: keyof TValue, value: unknown) => void;
  onChange: (event: React.ChangeEvent<unknown>) => void;
}

type Action<TValues> =
  | { type: 'UPDATE_VALUE'; payload: { key: keyof TValues; value: unknown } }
  | { type: 'RESET'; payload: { values: TValues } };

interface Props<TValues> {
  initialValues: TValues;
  contextRef?: React.Ref<FormContext<TValues>>;
  children: (context: FormContext<TValues>) => JSX.Element;
  onSubmit: (values: TValues) => void | Promise<unknown>;
  onChange?: (key: keyof TValues, value: unknown) => void;
}

export function Form<TValues>({ initialValues, onChange, ...props }: Props<TValues>) {
  const [state, dispatch] = useReducer<React.Reducer<State<TValues>, Action<TValues>>>(reducer, {
    values: initialValues,
  });

  const reset = useCallback(() => {
    dispatch({ type: 'RESET', payload: { values: initialValues } });
  }, [initialValues]);

  const setValue = useCallback(
    (key: keyof TValues, value: unknown) => {
      if (process.env.NODE_ENV !== 'production') {
        if (!(key in initialValues)) {
          console.warn('Form: onChange handler found a key that is not part of the initialValues');
        }
      }
      dispatch({ type: 'UPDATE_VALUE', payload: { key, value } });
    },
    [initialValues]
  );

  function internalOnSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    props.onSubmit(state.values);
  }

  const internalOnChange = useCallback(
    (event: React.ChangeEvent<unknown>) => {
      // Is this required? It seems to only make sense if we use the event object asynchronously.
      // https://reactjs.org/docs/events.html#event-pooling
      event.persist();

      const target = (event.target ?? event.currentTarget) as HTMLInputElement & HTMLSelectElement;
      const { name, id, type, value, multiple, options } = target;
      const key = (name ?? id) as keyof TValues;

      let finalValue: number | string | string[] = value;
      if (/number|range/.test(type)) {
        finalValue = parseFloat(value);
      } else if (/datetime-local/.test(type)) {
        // Fix iOS bug that will send the string in the wrong format.
        // The right format is yyyy-MM-ddThh:mm but Safari based browsers send yyyy-MM-ddThh:mm.ss.sss
        // https://stackoverflow.com/a/53935705/2105988
        finalValue = value.substr(0, 16);
      } else if (/checkbox/.test(type)) {
        // https://github.com/formik/formik/blob/master/packages/formik/src/Formik.tsx#L1150
        // getValueForCheckbox(getIn(state.values, field!), checked, value)
      } else if (multiple) {
        finalValue = Array.from(options)
          .filter((element) => element.selected)
          .map((el) => el.value);
      }

      setValue(key, finalValue);

      if (onChange) onChange(key as keyof TValues, finalValue);
    },
    [onChange, setValue]
  );

  const context = {
    ...state,
    onChange: internalOnChange,
    setValue,
    reset,
  };

  useImperativeHandle(props.contextRef, () => context);

  return <form onSubmit={internalOnSubmit}>{props.children(context)}</form>;
}

function reducer<TValues>(state: State<TValues>, action: Action<TValues>) {
  switch (action.type) {
    case 'UPDATE_VALUE':
      return {
        ...state,
        values: {
          ...state.values,
          [action.payload.key]: action.payload.value,
        },
      };
    case 'RESET':
      return {
        ...state,
        values: action.payload.values,
      };
    default:
      throw new Error(`Unhandled action type: ${(action as Action<TValues>).type}`);
  }
}
