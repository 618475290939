import React, { useState } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import {
  Card,
  CardHeader,
  IconButton,
  CardContent,
  Typography,
  CardActions,
  Collapse,
  Grid,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from '@material-ui/core';
import { MoreVert, ExpandMore, Edit, Delete } from '@material-ui/icons';
import clsx from 'clsx';

import { ChartEntity } from '../../../domain/chart';
import { ConfirmationDialog } from '../../ConfirmationDialog';

const subheaderTypographyProps = {
  noWrap: true,
};

const titleTypographyProps = {
  noWrap: true,
};

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      deleteMenuItem: {
        color: theme.palette.warning[theme.palette.type],
        '& svg': {
          color: theme.palette.warning[theme.palette.type],
        },
      },
      expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
          duration: theme.transitions.duration.shortest,
        }),
      },
      expandOpen: {
        transform: 'rotate(180deg)',
      },
      cardHeader: {
        // https://css-tricks.com/flexbox-truncated-text/
        '& .MuiCardHeader-content': {
          minWidth: 0,
        },
        // Reserve space for one line of text to keep the card height constant
        // https://stackoverflow.com/a/33298969/2105988
        '& .MuiCardHeader-subheader::after': {
          content: '"\\feff"',
          visibility: 'hidden',
        },
      },
      cardActions: {
        // Reserve the same space when the expand indicator is not present to keep the card height constant
        minHeight: theme.spacing(8),
      },
    }),
  { name: 'ChartCard' }
);

export interface ChartCardProps {
  chartEntity: ChartEntity<unknown>;
  chartUI: React.ReactElement;
  chartSummaryUI?: React.ReactElement;
  subheader?: string;
  onEdit?: (chartEntity: ChartEntity<unknown>) => void;
  onDelete?: (chartId: ID) => void;
}

export const ChartCard: React.FC<ChartCardProps> = ({
  chartEntity,
  chartUI,
  chartSummaryUI,
  subheader,
  onEdit,
  onDelete,
}) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const [menuAnchorElement, setMenuAnchorElement] = useState<HTMLElement | null>(null);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);

  function handleExpandClick() {
    setExpanded((value) => !value);
  }

  function handleMenuOpen(event: React.MouseEvent<HTMLElement>) {
    setMenuAnchorElement(event.currentTarget);
  }

  function handleMenuClose() {
    setMenuAnchorElement(null);
  }

  function handleEditClick() {
    handleMenuClose();
    onEdit && onEdit(chartEntity);
  }

  function handleDeleteClick() {
    handleMenuClose();
    setConfirmationDialogOpen(true);
  }

  function handleConfirmationDialogClose() {
    setConfirmationDialogOpen(false);
  }

  function handleConfirmationDialogAccept() {
    handleConfirmationDialogClose();
    onDelete && onDelete(chartEntity.id);
  }

  function handleConfirmationDialogCancel() {
    handleConfirmationDialogClose();
  }

  const showMenu = !!onEdit || !!onDelete;
  const isMenuOpen = !!menuAnchorElement;
  const menuId = `chart-menu-${chartEntity.id}`;
  const menu = showMenu ? (
    <Menu anchorEl={menuAnchorElement} id={menuId} open={isMenuOpen} onClose={handleMenuClose}>
      {onEdit ? (
        <MenuItem onClick={handleEditClick}>
          <ListItemIcon>
            <Edit fontSize="small" />
          </ListItemIcon>
          <ListItemText>Edit</ListItemText>
        </MenuItem>
      ) : null}
      {onDelete ? (
        <MenuItem className={classes.deleteMenuItem} onClick={handleDeleteClick}>
          <ListItemIcon>
            <Delete fontSize="small" />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>
      ) : null}
    </Menu>
  ) : null;

  const hasCollapsedContent = !!chartEntity.notes || !!chartSummaryUI;
  return (
    <Card>
      <CardHeader
        action={
          showMenu ? (
            <IconButton aria-label="settings" onClick={handleMenuOpen}>
              <MoreVert />
            </IconButton>
          ) : undefined
        }
        className={classes.cardHeader}
        subheader={
          <Tooltip title={subheader ? subheader : ''}>
            <span>{subheader}</span>
          </Tooltip>
        }
        subheaderTypographyProps={subheaderTypographyProps}
        title={
          <Tooltip title={chartEntity.title}>
            <span>{chartEntity.title}</span>
          </Tooltip>
        }
        titleTypographyProps={titleTypographyProps}
      />
      <CardContent>
        <Grid alignItems="center" container justify="center">
          {chartUI}
        </Grid>
      </CardContent>
      <CardActions disableSpacing>
        <IconButton
          aria-expanded={expanded}
          aria-label="show more"
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
          onClick={handleExpandClick}
        >
          <ExpandMore />
        </IconButton>
      </CardActions>
      {hasCollapsedContent ? (
        <Collapse in={expanded} timeout="auto">
          <CardContent>
            {chartEntity.notes ? (
              <>
                <Typography variant="h6">Notes:</Typography>
                <Typography color="textSecondary" component="p">
                  {chartEntity.notes}
                </Typography>
              </>
            ) : null}
            {chartSummaryUI}
          </CardContent>
        </Collapse>
      ) : null}
      {menu}
      <ConfirmationDialog
        content="Are you sure you want to permanently delete this chart?"
        open={confirmationDialogOpen}
        type="warning"
        onAccept={handleConfirmationDialogAccept}
        onCancel={handleConfirmationDialogCancel}
      />
    </Card>
  );
};
