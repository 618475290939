import React from 'react';
import { PieLabelRenderProps } from 'recharts';

const RADIAN = Math.PI / 180;

export const PieSectorLabel: React.FC<PieLabelRenderProps> = ({
  cx,
  cy,
  innerRadius,
  outerRadius,
  midAngle,
  percent,
  label,
  subLabel,
}) => {
  if (typeof cx !== 'number') return null;
  if (typeof cy !== 'number') return null;
  if (typeof innerRadius !== 'number') return null;
  if (typeof outerRadius !== 'number') return null;
  if (typeof midAngle !== 'number') return null;
  if (typeof percent !== 'number') return null;

  const radius = innerRadius + (outerRadius - innerRadius) * 0.4;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <g>
      <text
        dominantBaseline="central"
        fill="currentColor"
        fontSize="15px"
        textAnchor={x > cx ? 'start' : 'end'}
        x={x}
        y={y}
      >
        {label}
      </text>
      <text
        dominantBaseline="central"
        fill="currentColor"
        fontSize="11px"
        fontWeight="600"
        textAnchor={x > cx ? 'start' : 'end'}
        x={x}
        y={y + 15}
      >
        {subLabel}
      </text>
    </g>
  );
};
