import { CssBaseline } from '@material-ui/core';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import {
  ThemeProvider,
  // https://github.com/mui-org/material-ui/issues/13394#issuecomment-619266831
  unstable_createMuiStrictModeTheme as createMuiTheme,
} from '@material-ui/core/styles';
import { PaletteOptions } from '@material-ui/core/styles/createPalette';
import { Overrides } from '@material-ui/core/styles/overrides';
import React, { useCallback, useContext, useMemo, useState } from 'react';

import { LocalStorageKeyTheme } from '../../utils/localStorageKeys';

declare module '@material-ui/core/styles/createPalette' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars -- Module augmentation works globally
  interface Palette {
    habitTextPositive: Palette['primary'];
    habitTextNegative: Palette['primary'];
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars -- Module augmentation works globally
  interface PaletteOptions {
    habitTextPositive: PaletteOptions['primary'];
    habitTextNegative: PaletteOptions['primary'];
  }
}

/*
 To update the theme use:
 - Color Tool: https://material.io/resources/color/#!/?view.left=1&view.right=0&primary.color=7B1FA2&secondary.color=1976D2&primary.text.color=ffffff
 - Theme Editor: https://react-theming.github.io/create-mui-theme/
*/

const lightPalette: PaletteOptions = {
  type: 'light' as const,
  primary: { main: '#7e57c2', light: '#b085f5', dark: '#4d2c91' },
  secondary: { main: '#1976d2', light: '#63a4ff', dark: '#004ba0' },
  habitTextPositive: { main: green[900] },
  habitTextNegative: { main: red[900] },
};

const darkPalette: PaletteOptions = {
  type: 'dark' as const,
  primary: { main: '#7e57c2', light: '#b085f5', dark: '#4d2c91' },
  secondary: { main: '#1976d2', light: '#63a4ff', dark: '#004ba0' },
  habitTextPositive: { main: green[800] },
  habitTextNegative: { main: red[900] },
};

const palette = {
  light: lightPalette,
  dark: darkPalette,
};

const overrides: Overrides = {
  MuiCssBaseline: {
    '@global': {
      'a.active': {
        // TODO: Ideally this should depend on palette.action.active
        // So far I haven't seen a way to use the default theme inside overrides
        color: 'rgba(0, 0, 0, 0.54)',
      },
    },
  },
};

type ThemeKey = 'light' | 'dark';

interface Value {
  themeKey: ThemeKey;
  setTheme: (theme: ThemeKey) => void;
}

const ThemeContext = React.createContext({} as Value);

const savedPreference = window.localStorage.getItem(LocalStorageKeyTheme);

const prefersDarkMode =
  savedPreference === 'dark' ||
  // Using "window.matchMedia" instead of "useMediaQuery" because the hook has some delay that will cause a flicker.
  (!savedPreference && window.matchMedia('(prefers-color-scheme: dark)'));

const AppTheme: React.FC = ({ children }) => {
  const [themeKey, setThemeKey] = useState<ThemeKey>(prefersDarkMode ? 'dark' : 'light');
  const theme = useMemo(() => createMuiTheme({ palette: palette[themeKey], overrides }), [
    themeKey,
  ]);
  const setTheme = useCallback((theme: ThemeKey) => {
    setThemeKey(theme);
    window.localStorage.setItem(LocalStorageKeyTheme, theme);
  }, []);
  const value = useMemo(() => ({ themeKey, setTheme }), [themeKey, setTheme]);
  return (
    <ThemeContext.Provider value={value}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </ThemeContext.Provider>
  );
};

function useAppTheme() {
  return useContext(ThemeContext);
}

export { AppTheme, useAppTheme };
