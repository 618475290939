import { useEffect } from 'react';
import { useFormikContext } from 'formik';

export interface FormikEffectProps<TValues> {
  onChange: (values: TValues) => void;
}

export const FormikValueChangeEffect = <TValues extends unknown>({
  onChange,
}: FormikEffectProps<TValues>) => {
  const { values, isValid, dirty } = useFormikContext<TValues>();

  useEffect(() => {
    if (isValid && dirty) {
      onChange(values);
    }
  }, [values, isValid, dirty, onChange]);

  return null;
};
