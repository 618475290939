import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Step,
  StepContent,
  StepLabel,
  Stepper,
} from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React, { useCallback, useMemo, useState } from 'react';

import { ChartEntity, ChartInput, ChartType, ChartVariant } from '../../../../domain/chart';
import { HabitEntity } from '../../../../domain/habit';
import { HabitFilter } from '../../../../domain/weakEntities/habitFilter';
import { Timestamp } from '../../../../firebase';
import { hasId } from '../../../../utils/hasId';
import { chartCardComponentMap } from '../../Charts/chartMap';
import { HabitsInfiniteList } from '../Filters/HabitsInfiniteList/HabitsInfiniteList';

import { ChartForm, ChartFormValues } from './ChartForm';

const stepTitle = [
  'Select chart type',
  'Filter habits',
  'Choose a time range',
  'Name chart',
  'Preview chart',
];

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        width: '100%',
      },
      button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
      },
      stepLabel: {
        cursor: 'pointer',
      },
      actionsContainer: {
        marginBottom: theme.spacing(2),
      },
    }),
  { name: 'Steps' }
);

interface Props {
  className?: string;
  chart: ChartInput<unknown> | ChartEntity<unknown>;
  habits: HabitEntity[];
  onFinalStepComplete: (chart: ChartInput<unknown> | ChartEntity<unknown>) => Promise<void>;
}

export const Steps: React.FC<Props> = ({ className, chart, habits, onFinalStepComplete }) => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- Fix this type
  const [chartInput, setChartInput] = useState<ChartInput<any>>(chart);

  const chartFormInitialValues = useMemo(() => ({ title: chart.title, notes: chart.notes }), [
    chart.title,
    chart.notes,
  ]);

  function handleTypeChange(_: React.ChangeEvent<HTMLInputElement>, value: string) {
    setChartInput((prevValue) => ({ ...prevValue, type: value as ChartType }));
  }

  function handleVariantChange(_: React.ChangeEvent<HTMLInputElement>, value: string) {
    setChartInput((prevValue) => ({
      ...prevValue,
      variant: value as ChartVariant,
      data: cleanData(prevValue.data),
    }));
  }

  function handleTimeRangeChange(_: React.ChangeEvent<HTMLInputElement>, value: string) {
    setChartInput((prevValue) => ({
      ...prevValue,
      data: { ...prevValue.data, timeRange: { ...prevValue.data.timeRange, type: value } },
    }));
  }

  const handleHabitFilterSelectionChange = useCallback((habitFilter: HabitFilter) => {
    setChartInput((prevValue) => ({
      ...prevValue,
      data: { ...prevValue.data, selectedHabits: habitFilter },
    }));
  }, []);

  const handleFormChange = useCallback((values: ChartFormValues) => {
    // Undefined properties cannot be saved in Firebase
    if (values.notes === undefined) {
      delete values.notes;
    }
    setChartInput((prevValue) => ({ ...prevValue, ...values }));
  }, []);

  async function handleNext() {
    if (activeStep + 1 < stepTitle.length) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else {
      onFinalStepComplete({
        ...chartInput,
        data: cleanData(chartInput.data),
      });
    }
  }

  function handleBack() {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  }

  function renderStepContent(index: number) {
    switch (index) {
      case 0:
        return (
          <Grid container justify="space-between">
            <Grid item>
              <FormControl required>
                <FormLabel component="legend">Type</FormLabel>
                <RadioGroup
                  aria-label="type"
                  name="chartInputType"
                  value={chartInput.type}
                  onChange={handleTypeChange}
                >
                  {/* TODO: Make this list depend on chartCardComponentMap */}
                  <FormControlLabel control={<Radio />} label="Independent" value="Independent" />
                  <FormControlLabel
                    control={<Radio />}
                    label="Positive vs Negative"
                    value="PositiveVsNegative"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl required>
                <FormLabel component="legend">Variant</FormLabel>
                <RadioGroup
                  aria-label="variant"
                  name="chartInputVariant"
                  value={chartInput.variant}
                  onChange={handleVariantChange}
                >
                  {/* TODO: Make this list depend on chartCardComponentMap */}
                  <FormControlLabel control={<Radio />} label="Stacked Bars" value="StackedBars" />
                  <FormControlLabel
                    control={<Radio />}
                    label="Stacked Areas"
                    value="StackedAreas"
                  />
                  <FormControlLabel control={<Radio />} label="Pie" value="Pie" />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
        );
      case 1:
        return (
          <HabitsInfiniteList
            initialSelection={chartInput.data.selectedHabits}
            onSelectionChange={handleHabitFilterSelectionChange}
          />
        );
      case 2:
        return (
          <FormControl required>
            <FormLabel component="legend">Time Range</FormLabel>
            <RadioGroup
              aria-label="time range"
              name="chartInputTimeRange"
              value={chartInput.data.timeRange.type}
              onChange={handleTimeRangeChange}
            >
              {/* TODO: Make this list depend on TimeRangeType */}
              <FormControlLabel control={<Radio />} label="Today" value="today" />
              <FormControlLabel control={<Radio />} label="Last Week" value="lastWeek" />
              <FormControlLabel control={<Radio />} label="Last Month" value="lastMonth" />
              <FormControlLabel control={<Radio />} label="Last Year" value="lastYear" />
              <FormControlLabel control={<Radio />} label="All" value="all" />
            </RadioGroup>
          </FormControl>
        );
      case 3:
        return <ChartForm initialValues={chartFormInitialValues} onChange={handleFormChange} />;
      case 4: {
        const ChartCardComponent = chartCardComponentMap[chartInput.type];
        return (
          <ChartCardComponent
            chartEntity={{ ...chartInput, id: 'temp-id' }}
            habits={habits}
            previewMode
          />
        );
      }
      default:
        return null;
    }
  }

  return (
    <Stepper
      activeStep={activeStep}
      className={clsx(classes.root, className)}
      orientation="vertical"
    >
      {stepTitle.map((label, index) => (
        <Step key={label}>
          <StepLabel className={classes.stepLabel} onClick={() => setActiveStep(index)}>
            {label}
          </StepLabel>
          <StepContent>
            {renderStepContent(index)}
            <div className={classes.actionsContainer}>
              <div>
                <Button className={classes.button} disabled={activeStep === 0} onClick={handleBack}>
                  Back
                </Button>
                <Button
                  className={classes.button}
                  color="primary"
                  variant="contained"
                  onClick={handleNext}
                >
                  {activeStep === stepTitle.length - 1
                    ? hasId(chartInput)
                      ? 'Update'
                      : 'Create'
                    : 'Next'}
                </Button>
              </div>
            </div>
          </StepContent>
        </Step>
      ))}
    </Stepper>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- Fix this type
function cleanData(data: any) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars -- Removes cachedData from data
  const { cachedData, lastUpdated, ...rest } = data;
  return {
    ...rest,
    lastUpdated: Timestamp.fromMillis(0),
  };
}
