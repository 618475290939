import { InstanceInput } from '../domain/instance';
import { habitsCollection, toEntity, habitInstancesCollection } from '../queries';
import { firebaseFirestore } from '../firebase';
import { HabitEntity, HabitStatistics } from '../domain/habit';

export function saveInstanceAndUpdateHabit(instanceInput: InstanceInput) {
  const habitDocument = habitsCollection().doc(instanceInput.habitId);
  return firebaseFirestore.runTransaction(async (transaction) => {
    const habit = await transaction.get(habitsCollection().doc(instanceInput.habitId));
    if (!habit.exists) throw new Error('Habit does not exist');

    const habitStatistics = computeHabitStatistics(toEntity<HabitEntity>(habit), instanceInput);
    transaction.update(habitDocument, habitStatistics);
    transaction.set(habitInstancesCollection().doc(), instanceInput);
  });
}

function computeHabitStatistics(habit: HabitEntity, newInstance: InstanceInput): HabitStatistics {
  const result = {
    lastInstanceDate: habit.lastInstanceDate,
    instanceCount: habit.instanceCount,
    totalInstanceTimeSeconds: habit.totalInstanceTimeSeconds,
    totalSetCount: habit.totalSetCount,
    maxSetCount: habit.maxSetCount,
    totalRepetitionsCount: habit.totalRepetitionsCount,
    maxRepetitionsInAnInstance: habit.maxRepetitionsInAnInstance,
    maxRepetitionsInASet: habit.maxRepetitionsInASet,
  };

  if (!result.lastInstanceDate || result.lastInstanceDate < newInstance.startDate) {
    result.lastInstanceDate = newInstance.startDate;
  }

  result.instanceCount = (result.instanceCount ?? 0) + 1;
  result.totalInstanceTimeSeconds =
    (result.totalInstanceTimeSeconds ?? 0) + (newInstance.durationSeconds ?? 0);
  result.totalSetCount = (result.totalSetCount ?? 0) + (newInstance.setCount ?? 0);
  result.maxSetCount = Math.max(result.maxSetCount ?? 0, newInstance.setCount ?? 0);
  result.totalRepetitionsCount =
    (result.totalRepetitionsCount ?? 0) + (newInstance.setRepetitionsCount ?? 0);
  result.maxRepetitionsInAnInstance = Math.max(
    result.maxRepetitionsInAnInstance ?? 0,
    newInstance.setRepetitionsCount ?? 0
  );

  result.maxRepetitionsInASet = (newInstance.sets ?? '')
    .split(',')
    .map((set) => Number(set))
    .reduce(
      (accumulator, currentValue) => Math.max(accumulator, currentValue),
      result.maxRepetitionsInASet ?? 0
    );

  return result;
}
