import { firebaseAnalytics } from '../firebase';

interface EventParams {
  [key: string]: unknown;
}

function logAnalyticsEvent(eventName: string, eventParams: EventParams) {
  firebaseAnalytics.logEvent(eventName, eventParams);
}

function logAnalyticsWarning(description: string, eventParams: EventParams) {
  firebaseAnalytics.logEvent('warning', { description, ...eventParams });
}

interface ExceptionEventParams extends EventParams {
  fatal?: boolean;
}

function logAnalyticsException(
  error: Error,
  description: string,
  eventParams: ExceptionEventParams
) {
  firebaseAnalytics.logEvent('exception', {
    description,
    errorMessage: error.message,
    ...eventParams,
  });
}

export { logAnalyticsEvent, logAnalyticsWarning, logAnalyticsException };
