const rootPath = createStaticRoute('', '/');

const loginPath = createStaticRoute(rootPath, '/login');

function validatePath(path: string) {
  if (!path.startsWith('/')) {
    throw new Error('Path must start with /');
  }
  if (path !== '/' && path.endsWith('/')) {
    throw new Error('Path must not end with /');
  }
}

function createStaticRoute(parent: string, path: string): string {
  validatePath(path);
  return `${parent}${path}`.replace('//', '/');
}

export { rootPath, loginPath };
